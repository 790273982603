export const sidebarLinks = {
  title: "Школа охранников",
  links: [
    {
      value: "security-guard-certificate",
      title: "Удостоверение частного охранника",
    },
    {
      value: "renewal-of-security-guard-certificate",
      title: "Продление удостоверения частного охранника",
    },
    {
      value: "training-for-security-guard",
      title: "Обучение по профессии частный охранник",
    },
    {
      value: "security-guard-exam",
      title: "Квалификационный экзамен",
    },
    {
      value: "periodic-test",
      title: "Периодическая проверка",
    },
    {
      value: "refresher-training",
      title: "Повышение квалификации",
    },
    {
      value: "licensing-of-security-guard",
      title: "Лицензирование охранников",
    },
  ],
};
