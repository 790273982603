import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Периодическая проверка</h1>
      <div>
        <p>
          Ремесло охранника - крайне ответственное. Ведь от его компетентности,
          от быстроты его реакции могут зависеть жизни людей.
        </p>
        <p>
          Согласно Закону «О частной охраной и детективной деятельности в РФ» от
          11.03.1992 и Приказа МВД России от 17.03.2015 с учетом изменений и
          дополнений периодическая проверка частных охранников 4,5 и 6 разрядов
          проводится один раз в год.
        </p>
        <p>Необходимые документы для сдачи периодической проверки:</p>
        <ul>
          <li>Паспорт гражданина РФ;</li>
          <li>Медицинская комиссия (форма 002+003+ХТИ)</li>
          <li>
            Уведомление о прохождении периодической проверки (Приложение 1
            Приказа Росгвардии 387 Об утверждении Порядка проведения
            периодических проверок)
          </li>
          <li>Удостоверение частного охранника</li>
          <li>
            Для граждан, не имеющих постоянной регистрации в СПб или ЛО – копия
            трудовой книжки, заверенная работодателем
          </li>
        </ul>
        <p>
          Режим работы Учебного центра &laquo;Собрат&raquo;:
          <br />
          понедельник - пятница с 09:00 до 18:00
          <br />
          Результат сдачи периодической проверки выдается в день сдачи.
        </p>
        <p>Периодическая проверка включает в себя:</p>
        <ul>
          <li>
            проверку знаний нормативных правовых актов Российской Федерации,
            регулирующих охранную деятельность, оборот оружия и (или)
            специальных средств, а также устанавливающих особенности, запреты и
            ограничения их применения;
          </li>
          <li>
            проверку практических навыков применения огнестрельного оружия и
            (или) специальных средств путем выполнения работниками упражнений:
            практических стрельб из разрешенного к использованию вида и типа
            огнестрельного оружия и (или) упражнений по практическому применению
            специальных средств.
          </li>
        </ul>
        <p></p>
        <p>
          Проверка теоретических знаний работников осуществляется с помощью
          компьютерного тестирования.
        </p>
        <h2>Периодическая проверка охранников 4 разряда</h2>
        <p>
          В тесте для частного охранника 4-го разряда содержится - 10 вопросов и
          3 варианта ответа (из них один правильный).
        </p>
        <p>
          Наличие четвертого разряда позволяет оказывать охранные услуги с
          помощью следующих средств - резиновой палки и наручников.
        </p>
        <p>
          В соответствии с этим в практической части задания проверяются
          следующие навыки:
        </p>
        <ul>
          <li>применение защитного шлема;</li>
          <li>применение защитного жилета;</li>
          <li>использование резиновой палки;</li>
          <li>использование наручников.</li>
          <li>
            <a
              target="_blank"
              href="/uploads/docs/Voprosy_i_otvety_dlya_sdachi_ekzamena_na_ohrannika_4_razryada2021.pdf"
            >
              Вопросы и ответы для сдачи экзамена
            </a>
          </li>
        </ul>
        <h2>Периодическая проверка охранников 5 разряда </h2>
        <p>
          Тест для частного охранника 5-го разряда включает в себя 10 вопросов и
          3 варианта ответа, один из которых правильный.
        </p>
        <p>
          5-й разряд дает возможность применять гражданское оружие самообороны -
          электрошокеры, газовые баллончики, пистолеты и т.д.
        </p>
        <p>
          Соответственно, в практической части охранники пятого разряда должны
          продемонстрировать владение:
        </p>
        <ul>
          <li>электрошоковыми устройствами (ЭШУ);</li>
          <li>
            аэрозолями со слезоточивыми или раздражающими веществами (газовыми
            балончиками).{" "}
          </li>
          <li>
            <a
              target="_blank"
              href="/uploads/docs/voprosy_i_otvety_dlya_sdachi_ekzamena_na_ohrannika_5_razryada2021.pdf"
            >
              Вопросы и ответы для сдачи экзамена
            </a>
          </li>
        </ul>
        <p></p>
        <h2>Периодическая проверка охранников 6 разряда</h2>
        <p>
          Тест для частного охранника 6-го разряда содержит 10 вопросов с 3
          вариантами ответов (один из них правильный).
        </p>
        <p>
          6-й разряд дает возможность использовать весь спектр разрешенного
          служебного огнестрельного оружия.
        </p>
        <p>
          Значит, охранник 6-го разряда должен продемонстрировать навыки
          владения оружием, а именно:
        </p>
        <ul>
          <li>прицельную стрельбу с места по неподвижной цели;</li>
          <li>
            прицельную стрельбу с места по появляющейся цели (в ограниченное
            время).
          </li>
          <li>
            <a
              target="_blank"
              href="/uploads/docs/voprosy_i_otvety_dlya_sdachi_ekzamena_na_ohrannika_6_razryada2021.pdf"
            >
              Вопросы и ответы для сдачи экзамена
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function PeriodicTest(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default PeriodicTest;
