import React from "react";
import Sidebar from "../Sidebar";
import PriceTabs from "./PriceTabs";

function PriceList(props) {
  return (
    <div className="d-flex flex-row container my-4">
      <div className="row">
        <div className="col-lg-8 mb-lg-5 px-lg-5 px-md-3 px-sm-1">
          <h3>Прайс-лист ЧОУ ДПО УЦ «Собрат»</h3>
          <PriceTabs />
        </div>
        <Sidebar />
      </div>
    </div>
  );
}

export default PriceList;
