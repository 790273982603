import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Квалификационный экзамен</h1>
      <div>
        <p>
          Для получения удостоверения частного охранника гражданам, обучившимся
          по программе "частный охранник", необходимо сдать квалификационный
          экзамен для присвоения квалификиции по выбранному разряду.{" "}
        </p>
        <p>Необходимые документы для сдачи квалификационного экзамена: </p>
        <ul>
          <li>паспорт гражданина РФ;</li>
          <li>свидетельство об окончании школы охранников;</li>
          <li>
            медицинская комиссия (форма 002 + хти + заключение на годность к
            ЧОД).
          </li>
        </ul>
      </div>
    </div>
  );
}

function GuardExam(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default GuardExam;
