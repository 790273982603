import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";
import Card from "react-bootstrap/Card";

function TargetComponent(props) {
  return (
    <div>
      <h1>Продление удостоверения частного охранника</h1>
      <div>
        <p>
          Для продления удостоверения частного охранника гражданам необходимо
          пройти обучение по программе повышения квалификации частных
          охранников.
        </p>
        <p>
          Для формирования личного дела гражданам необходимо предоставить
          следующие документы:{" "}
        </p>
        <ul>
          <li>паспорта гражданина РФ; </li>
          <li>свидетельство о повышении квалификации;</li>
          <li>свидетельство о сдаче квалификационного экзамена;</li>
          <li>акт о результатах периодических проверок;</li>
          <li>медицинская комиссия 002;</li>
          <li>справка по форме 454/у-06;</li>
          <li>cправка о прохождении дактилоскопической регистрации;</li>
          <li>копия трудовой книжки;</li>
          <li>квитанция об оплате государственной пошлины - 650 рублей</li>
          <li>фотографии 4*6 матовые цветные - 4 штуки.</li>
        </ul>

        <Card>
          <Card.Img
            variant="top"
            alt="получение удостоверения частного охранника"
            src="/uploads/udostoverinie.jpg"
            title="Удостоверение частного охранника"
            className="img-thumbnail img-fluid"
          />
          <Card.Body>
            <Card.Text className="text-center">
              Форма удостоверения частного охранника
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

function CertRenewal(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default CertRenewal;
