import React from "react";

function Map({ height = 620 }) {
  return (
    <iframe
      className="container"
      title="sobrat map"
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A0ed571c16607ee55eba8232eedaa226cf3f8a26369ce290edf80f1e63e9a51c2&amp;source=constructor"
      width="100%"
      height={height}
    ></iframe>
  );
}

export default React.memo(Map);

