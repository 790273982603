import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Лицензирование охранников</h1>
      <div>
        <p>
          Если вы хотите стать частным охранником, вам необходимо получить
          лицензию ЦЛРР (центра, который осуществляет лицензионно-разрешительные
          работы). Для этого нужно сначала пройти профессиональную подготовку
          (это можно сделать в школе охранников Учебного центра
          &laquo;Собрат&raquo;), затем сдать комплексный экзамен (включающий
          теоретическую и практическую часть), пройти дактилоскопическую
          регистрацию, получить медицинское заключение формы 002. После этого
          можно оплачивать госпошлину и подавать личное дело в Центр
          лицензионно-разрешительных работ.
        </p>
        <p>
          Для формирования личного дела потребуется следующий пакет документов:
        </p>
        <ul>
          <li> паспорт гражданина РФ;</li>
          <li> свидетельство об окончании курса обучения;</li>
          <li>
            медицинская форма 002 с заключением об отсутствии заболеваний,
            мешающих исполнению обязанностей частного охранника;
          </li>
          <li>
            заключение по форме 454/у-06 (химико-токсикологическое
            исследование);
          </li>
          <li>
            справка о прохождении обязательной дактилоскопической регистрации;
          </li>
          <li>
            копия трудовой книжки (для тех, кто прошел государственную службу -
            выписка из приказа об увольнении);
          </li>
          <li> квитанция об оплате госпошлины;</li>
          <li> фотографии.</li>
        </ul>
        <p>
          Более подробную информацию, а также помощь в подготовке пакета
          документов, вы можете получить у специалистов Учебного центра
          &laquo;Собрат&raquo;, позвонив по телефону{" "}
          <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
            {process.env.REACT_APP_TELEPHONE_USER}
          </a>
        </p>
      </div>
    </div>
  );
}

function GuardLicensing(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default GuardLicensing;
