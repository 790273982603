import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Лицензия на приобретение гражданского оружия</h1>
      <div>
        <p>
          С 2012 года в силу вступили поправки в закон «Об оружии», согласно
          которому все гражданские лица, впервые подающие документы на получение
          лицензии на оружие, обязаны проходить специальное обучение. А при
          продлении разрешений предоставляются документы, подтверждающие
          проверку знаний правил безопасного обращения с оружием.
        </p>
        <p>
          Процедуры получения лицензии для различных видов оружия в целом схожи,
          но есть и некоторые различия:
        </p>
        <ul>
          <li>
            получение лицензии на охотничье гладкоствольное оружие: перечислим
            основные этапы - нужно выбрать марку оружия, обратиться в ЛРО
            (лицензионно-разрешительный отдел) с заявлением и необходимыми
            документами (их перечень приведен ниже), выбрать марку оружия,
            дождаться решения о выдаче лицензии, после этого уже приобрести
            оружие;
          </li>
          <li>
            получение лицензии на нарезное оружие: сама процедура практически
            идентична предыдущей (для охотничьего гладкоствольного оружия), но
            нужно отметить, что для того, чтобы подать заявление на получение
            лицензии на приобретение нарезного оружия, вам необходимо минимум 5
            лет являться владельцем гладкоствольного оружия, а также охотничьего
            билета;
          </li>
          <li>
            получение лицензии на травматическое оружие: процесс получения
            лицензии практически не отличается от описанных ранее, разница по
            большому счету будет лишь в списке документов (он приведен ниже).
          </li>
        </ul>
        <h2>Документы для лицензии на оружие</h2>
        <p>
          Необходимый пакет документов для оружия ограниченного поражения
          (травматического оружия):
        </p>
        <ul>
          <li>паспорт гражданина РФ;</li>
          <li>
            медицинское заключение об отсутствии противопоказаний к владению
            оружием (справка о результатах химико-токсикологических исследований
            (форма 454/у-06)
          </li>
          <li>
            медицинское заключение по форме 003-О/у (от нарколога по месту
            прописки)
          </li>
          <li>
            медицинское заключение об отсутствии медицинских противопоказаний к
            владению оружием по форме 002-О/у
          </li>
          <li>
            документ о прохождении подготовки и проверки знаний и навыков
            безопасного обращения с оружием
          </li>
          <li>
            акт проверки условий хранения оружия по месту жительства (после
            подачи документов, инспектор ЛРР лично, либо участковый по запросу
            инспектора ЛРР, выезжают на адрес по месту хранения оружия для
            составления акта)
          </li>
          <li>квитанция об оплате государственной пошлины</li>
          <li>фотографии 3х4 матовые</li>
        </ul>
        <p>
          Необходимый пакет документов для охотничьего огнестрельного
          гладкоствольного оружия:
        </p>
        <ul>
          <li>паспорт гражданина РФ;</li>
          <li>
            медицинское заключения об отсутствии противопоказаний к владению
            оружием (справка о результатах химико-токсикологических исследований
            (форма 454/у-06)
          </li>
          <li>
            медицинское заключение по форме 003-О/у (от нарколога по месту
            прописки)
          </li>
          <li>
            медицинское заключение об отсутствии медицинских противопоказаний к
            владению оружием по форме 002-О/у
          </li>
          <li>
            документ о прохождении подготовки и проверки знаний и навыков
            безопасного обращения с оружием
          </li>
          <li>
            охотничий билет единого федерального образца или документа,
            подтверждающего занятие видами спорта, связанными с использованием
            огнестрельного оружия, а также ходатайство аккредитованной
            общероссийской спортивной федерации о выдаче соответствующей
            лицензии с указанием вида спорта
          </li>
          <li>
            акт проверки условий хранения оружия по месту жительства (после
            подачи документов, инспектор ЛРР лично, либо участковый по запросу
            инспектора ЛРР, выезжают на адрес по месту хранения оружия для
            составления акта)
          </li>
          <li>квитанция об оплате государственной пошлины</li>
          <li>фотографии 3х4 матовые</li>
        </ul>
        <p>Необходимый пакет документов для нарезного оружия: </p>
        <ul>
          <li>
            В целом пакет документов идентичен предыдущему (для охотничьего
            гладкоствольного оружия).
          </li>
          <li>
            Лицензия на нарезное оружие выдается только через пять лет владения
            гладкоствольным оружием
          </li>
        </ul>
        <p>
          <b>
            Получить подробную консультацию по получению лицензии на оружие вы
            сможете, позвонив по телефону
            <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
              {process.env.REACT_APP_TELEPHONE_USER}
            </a>
          </b>
        </p>
        <p align="center">
          {" "}
          <img
            alt="Что нужно что бы получить лицензию ?"
            src="/uploads/pamyatka.jpg"
            title="Что нужно что бы получить лицензию ?"
            className="img-fluid img-thumbnail"
          />
        </p>{" "}
      </div>
    </div>
  );
}
function GunLicense(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default GunLicense;
