import React from "react";
import Table from "react-bootstrap/Table";

function PriceTabs() {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Наименование услуги</th>
          <th>Кол-во часов</th>
          <th>Стоимость, руб.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="3">
            <b>Обучение</b>
          </td>
        </tr>
        <tr>
          <td> - безопасному обращению с оружием (первичное/продление)</td>
          <td>6</td>
          <td>5000/2600</td>
        </tr>
        <tr>
          <td> - по профессии «охранник» 6 разряд</td>
          <td>80</td>
          <td>9500</td>
        </tr>
        <tr>
          <td> - по профессии «охранник» 5 разряд</td>
          <td>60</td>
          <td>7700</td>
        </tr>
        <tr>
          <td> - по профессии «охранник» 4 разряд</td>
          <td>40</td>
          <td>6000</td>
        </tr>
        <tr>
          <td> - работников юридических лиц с особыми уставными задачами</td>
          <td>40</td>
          <td>7500</td>
        </tr>
        <tr>
          <td> - модульное дообучение с 4 на 6 разряд</td>
          <td>40</td>
          <td>5700</td>
        </tr>
        <tr>
          <td> - модульное дообучение с 4 на 5 разряд</td>
          <td>20</td>
          <td>3700</td>
        </tr>
        <tr>
          <td> - модульное дообучение с 5 на 6 разряд</td>
          <td>20</td>
          <td>4700</td>
        </tr>
        <tr>
          <td colspan="3">
            <b>Повышение квалификации</b>
          </td>
        </tr>
        <tr>
          <td> - по профессии «охранник» 6 разряд</td>
          <td>20</td>
          <td>5000</td>
        </tr>
        <tr>
          <td> - по профессии «охранник» 5 разряд</td>
          <td>16</td>
          <td>4500</td>
        </tr>
        <tr>
          <td> - по профессии «охранник» 4 разряд</td>
          <td>8</td>
          <td>3500</td>
        </tr>
        <tr>
          <td>
            - программа обучения для руководителей частных охранных организаций,
            назначаемых на должность впервые
          </td>
          <td>80</td>
          <td>25000</td>
        </tr>
        <tr>
          <td>
            - программа повышения квалификации руководителей частных охранных
            организаций
          </td>
          <td>20</td>
          <td>7500</td>
        </tr>
        <tr>
          <td colspan="3">
            <b>Подготовка</b>
          </td>
        </tr>
        <tr>
          <td> - к ежегодной периодической проверке 6 разряда</td>
          <td>1 день</td>
          <td>3700</td>
        </tr>
        <tr>
          <td> - к ежегодной периодической проверке 5 разряда</td>
          <td>1 день</td>
          <td>3100</td>
        </tr>
        <tr>
          <td> - к ежегодной периодической проверке 4 разряда</td>
          <td>1 день</td>
          <td>2900</td>
        </tr>
        <tr>
          <td>
            - к ежегодной периодической проверке работников юридических лиц с
            особыми уставными задачами
          </td>
          <td>1 день</td>
          <td>2900/3700</td>
        </tr>
        <tr>
          <td>
            - подготовка и оформление дела для подачи на получение удостоверения
            частного охранника
          </td>
          <td>1 день</td>
          <td>1600</td>
        </tr>
        <tr>
          <td colspan="3">
            <b>Комплексный пакет</b>
          </td>
        </tr>
        <tr>
          <td> - комплексный пакет по профессии «охранник» 4 разряд</td>
          <td></td>
          <td>10600</td>
        </tr>
        <tr>
          <td> - комплексный пакет по профессии «охранник» 5 разряд</td>
          <td></td>
          <td>12600</td>
        </tr>
        <tr>
          <td> - комплексный пакет по профессии «охранник» 6 разряд</td>
          <td></td>
          <td>14600</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default PriceTabs;
