import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { v4 as uuidv4 } from "uuid";
import Map from "../Map/Map";

function Home(props) {
  const [photos, setPhotos] = useState([
    {
      src: "/uploads/images/3.webp",
      title: "",
      description: "",
      loading: "eager"
    },
    {
      src: "/uploads/images/4.webp",
      title: "",
      description: "",
      loading: "eager"
    },
    {
      src: "/uploads/images/9.webp",
      title: "",
      description: "",
      loading: "lazy"
    },
    {
      src: "/uploads/images/7.webp",
      title: "",
      description: "",
      loading: "lazy"
    },
    {
      src: "/uploads/images/2.webp",
      title: "",
      description: "",
      loading: "lazy"
    },
  ]);
  return (
    <>
      <Carousel fade className="w-100">
        {photos.map((photo) => (
          <Carousel.Item
            key={uuidv4()}
            className="d-flex"
            style={{ height: "50vh" }}
          >
            <img
              className="img-fluid"
              src={photo.src}
              alt={photo.title}
              style={{
                width: "100%",
                heigth: "100%",
                objectFit: "cover",
              }}
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="px-4 py-3 my-3 text-center">
        <img
          className="d-block mx-auto mb-4"
          src="/logo-footer.png"
          alt=""
          width="100"
          height="100"
        />
        <h1 className="display-5 fw-bold">
          Учебный центр &laquo;Собрат&raquo;
        </h1>

        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            Сотрудник службы безопасности - ответственная профессия, поэтому
            выбирать учебное заведение следует тщательно. <br />
            Выбор Учебного центра &laquo;Собрат&raquo;, как центра подготовки
            специалистов в этой области — надёжный вариант.
          </p>
        </div>
      </div>
      <div className="container px-4">
        <h2 className="pb-2 border-bottom text-center">Наши услуги</h2>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
          <div className="feature col">
            <div className="feature-icon bg-primary bg-gradient"></div>
            <h3>Безопасное обращение с оружием</h3>
            <p>
              Учебный центр &laquo;Собрат&raquo; проводит качественное обучение
              граждан правилам безопасного обращения с оружием (охотничьим,
              самообороны, спортивным). Пройдя полный курс обучения, вы получите
              ценные знания о том, как правильно и безопансо обращаться с
              оружием разного типа.
            </p>
          </div>
          <div className="feature col">
            <div className="feature-icon bg-primary bg-gradient"></div>
            <h3>Разрешение на оружие</h3>
            <p>
              Проведение проверки знаний безопасного обращения с оружием с целью
              продления разрешения на оружие самообороны. Подготовка документов
              для оформления лицензии на приобретение гражданского оружия.
            </p>
          </div>
          <div className="feature col">
            <div className="feature-icon bg-primary bg-gradient"></div>
            <h3>Профессия "Частный охранник"</h3>
            <p>
              Обучение охранников с дальнейшим получением профессии частного
              охранника всех разрядов. Организация и подготовка к проведению
              периодических проверок частных охранников. Организация
              квалификационного экзамена. Подготовка личного дела для получения
              удостоверения.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-dark text-secondary px-4 py-5 mb-3 text-center">
        <div className="py-5">
          <h1 className=" fw-bold text-white">
            Хотите пройти обучение или получить разрешение на оружие?
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="fs-5 mb-4">
              Свяжитесь с нашими специалистами удобным для вас способом. Мы с
              радостью ответим на все ваши вопросы и запишем для получения
              необходимых услуг.
            </p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <a href={"tel:" + process.env.REACT_APP_TELEPHONE_HREF}>
                <button
                  type="button"
                  className="btn btn-outline-light btn-lg px-4 m-2"
                >
                  Позвонить нам
                </button>
              </a>
              <a
                href={
                  "mailto:" +
                  process.env.REACT_APP_EMAIL +
                  "?body=Привет! \n Хочу пройти обучение в вашем центре!"
                }
              >
                <button
                  type="button"
                  className="btn btn-outline-light btn-lg px-4 m-2"
                >
                  Написать на e-mail
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center my-5">
        <h2 className="pb-2 border-bottom text-center">Наше расположение</h2>
        <Map />
      </div>
    </>
  );
}

export default Home;
