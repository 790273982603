import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Law from "./Law";

function Laws(props) {
  const [laws, setLaws] = useState([
    {
      id: 1,
      title: 'Федеральный Закон РФ № 150 - ФЗ "Об оружии" от 13.12.1996г.',
      src: "/uploads/docs/laws/1-121-102872.pdf",
    },
    {
      id: 2,
      title:
        'Федеральный Закон РФ № 398 - ФЗ "О внесении изменений в отдельные законодательные акты РФ по вопросу усиления контроля в сфере оборота гражданского оружия" от 28.12.2010г.',
      src: "/uploads/docs/laws/2-115-26238.pdf",
    },
    {
      id: 3,
      title:
        'Федеральный Закон РФ № 128 - ФЗ "О Государственной дактилоскопической регистрации в РФ" от 25.07.1998г.',
      src: "/uploads/docs/laws/3-116-86422.pdf",
    },
    {
      id: 4,
      title:
        'Федеральный Закон РФ № 272 - ФЗ "О внесении изменений в отдельные законодательные акты РФ в связи с совершенствованием государственного контроля в сфере частной охраной и детективной деятельности" от 22.12.2008г.',
      src: "/uploads/docs/laws/4-117-3445.pdf",
    },
    {
      id: 5,
      title:
        'Федеральный Закон РФ № 301 - ФЗ "О внесении изменений в закон РФ О частной детективной и охранной деятельности в РФ" от 15.11.2010г.',
      src: "/uploads/docs/laws/5-118-50171.pdf",
    },
    {
      id: 6,
      title:
        'Федеральный Закон РФ № 2487-1 "О частной детективной и охранной деятельности в РФ" от 11.03.1992г.',
      src: "/uploads/docs/laws/6-119-86182.pdf",
    },
    {
      id: 7,
      title:
        'Федеральный Закон РФ № 111- ФЗ "О внесении изменений в Федеральный Закон Об оружии" от 31.05.2010',
      src: "/uploads/docs/laws/7-114-6669.pdf",
    },
    {
      id: 8,
      title:
        "Федеральный закон № 99-ФЗ от 04.05.2011 г. «О лицензировании отдельных видов деятельности»",
      src: "/uploads/docs/laws/8-133-4583.pdf",
    },
    {
      id: 9,
      title:
        "Федеральный закон от 29.12.2012 № 273-ФЗ «Об образовании в Российской Федерации» в редакции от 29.12.2017 года",
      src: "/uploads/docs/laws/9-273-FZ_Ob_Obrazovanii.doc",
    },
    {
      id: 10,
      title: "Информация для граждан – владельцев оружия",
      src: "/uploads/docs/laws/10-info.pdf",
    },
  ]);
  return (
    <div className="d-flex flex-row container my-4">
      <div className="row">
        <div className="col-lg-8 mb-5 px-5">
          <h3>ЗАКОНЫ РФ</h3>
          <Law laws={laws} />
        </div>
        <Sidebar />
      </div>
    </div>
  );
}

export default Laws;
