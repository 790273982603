import React from "react";
import { Link } from "react-router-dom";
import Map from "../Map/Map";

function Sidebar({ sidebarLinks: { title, links } }) {
  return (
    <div className="col-lg-4">
      <div className="sticky-lg-top pt-lg-3">
        <div className="card mb-4">
          <div className="card-header">{title}</div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <ul className="list-unstyled mb-0 ">
                  {links.map((link, index) => (
                    <Link
                      key={index}
                      to={`../${link.value}`}
                      className="text-decoration-none"
                    >
                      <li role="link">{link.title}</li>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-header">Ждём вас в нашем центре</div>
          <div className="card-body">
            <Map height={300} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
