import React, { useState } from "react";
import Sidebar from "../Sidebar";
import MediaCarousel from "./MediaCarousel";

function Media(props) {
  const [photos, setPhotos] = useState([
    {
      src: "/uploads/images/1.webp",
      title: "",
      description: "",
      loading: "eager"
    },
    {
      src: "/uploads/images/5.webp",
      title: "",
      description: "",
      loading: "eager"
    },
    {
      src: "/uploads/images/3.webp",
      title: "",
      description: "",
      loading: "eager"
    },
    {
      src: "/uploads/images/4.webp",
      title: "",
      description: "",
      loading: "lazy"
    },
    {
      src: "/uploads/images/6.webp",
      title: "",
      description: "",
      loading: "lazy"
    },
    {
      src: "/uploads/images/7.webp",
      title: "",
      description: "",
      loading: "lazy"
    },
    {
      src: "/uploads/images/8.webp",
      title: "",
      description: "",
      loading: "lazy"
    },
  ]);

  return (
    <div className="d-flex flex-row container my-4">
      <div className="row">
        <div className="col-lg-8 mb-5 px-5">
          <h3>Галерея</h3>
          <MediaCarousel photos={photos} />
        </div>
        <Sidebar />
      </div>
    </div>
  );
}

export default Media;
