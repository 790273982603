import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Dropdown from "../Dropdown/Dropdown";
import "./Header.css";
import headerLogo from "./logo.png";

function Header(props) {
  const [about, setAbout] = useState({
    title: "Об организации",
    route: "/organisation",
    items: [
      {
        link: "/organisation/trainers",
        title: "Тренеры",
      },
      {
        link: "/organisation/price-list",
        title: "Прайс-лист",
      },
      {
        link: "/organisation/media",
        title: "Галерея",
      },
      {
        link: "/organisation/about",
        title: "Учебный центр «Собрат»",
      },
    ],
  });

  const [guardSchool, setGuardSchool] = useState({
    title: "Школа охранников",
    route: "/guards-school",
    items: [
      {
        link: "/guards-school/security-guard-certificate",
        title: "Удостоверение частного охранника",
      },
      {
        link: "/guards-school/renewal-of-security-guard-certificate",
        title: "Продление удостоверения ЧО",
      },
      {
        link: "/guards-school/training-for-security-guard",
        title: "Обучение по профессии частный охранник",
      },
      {
        link: "/guards-school/security-guard-exam",
        title: "Квалификационный экзамен",
      },
      {
        link: "/guards-school/periodic-test",
        title: "Периодическая проверка",
      },
      {
        link: "/guards-school/refresher-training",
        title: "Повышение квалификации",
      },
      {
        link: "/guards-school/licensing-of-security-guard",
        title: "Лицензирование охранников",
      },
    ],
  });

  const [safeGun, setSafeGun] = useState({
    title: "Безопасное обращение с оружием",
    route: "/safe-gun-handling",
    items: [
      {
        link: "/safe-gun-handling/safe-gun-handling-study",
        title: "Обучение безопасному обращению с оружием",
      },
      {
        link: "/safe-gun-handling/license-for-civil-gun",
        title: "Лицензия на приобритение гражданского оружия",
      },
      {
        link: "/safe-gun-handling/gun-permit",
        title: "Разрешение на оружие",
      },
      {
        link: "/safe-gun-handling/renewal-gun-permit",
        title: "Продление разрешения на оружие",
      },
      {
        link: "/safe-gun-handling/shooting-range",
        title: "Стрелковый тир",
      },
      {
        link: "/safe-gun-handling/medical-board",
        title: "Медицинская комиссия",
      },
    ],
  });

  const [docs, setDocs] = useState({
    title: "Документы",
    route: "/docs",
    items: [
      {
        link: "/docs/resolution-of-russian-federation",
        title: "Постановления правительства",
      },
      {
        link: "/docs/orders",
        title: "Приказы",
      },
      {
        link: "/docs/laws-of-russia",
        title: "Законы РФ",
      },
      {
        link: "/uploads/docs/school-license.pdf",
        title: "Лицензия на образовательную деятельность",
        href: true,
      },
    ],
  });

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      text="white"
      // fixed="top"
    >
      <Container>
        {/* LOGO */}
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              src={headerLogo}
              width="60"
              height="60"
              alt="&laquo;Собрат&raquo;"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Brand className="header__title header__title_mobile">
          Учебный центр &quot;СОБРАТ&quot;
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="justify-content-center">
          <Nav className="justify-content-between header-media-font">
            {/* Об организации */}
            <Dropdown dropdown={about} className="py-0" />
            {/* Школа охранников */}
            <Dropdown dropdown={guardSchool} />
            {/* Безопасное обращение с оружием */}
            <Dropdown dropdown={safeGun} />
            {/* Новости */}
            {/* <LinkContainer to="/news">
              <Nav.Link className="mx-1">Новости</Nav.Link>
            </LinkContainer> */}
            {/* Документы */}
            <Dropdown dropdown={docs} />
            {/* Контакты */}
            <LinkContainer to="/contacts">
              <Nav.Link className="mx-1">Контакты</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
