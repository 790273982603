import React from "react";

function MainContainer({ TargetComponent }) {
  return (
    <div className="col-lg-8 mb-4 px-5">
      <TargetComponent />
    </div>
  );
}

export default MainContainer;
