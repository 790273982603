import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Повышение квалификации</h1>
      <div>
        <p>
          Учебный центр &laquo;Собрат&raquo; проводит курсы повышения
          квалификации по профессии охранник (4, 5, 6 разряд).
        </p>
        <p>
          Для зачисления на курсы охранников необходимы следующие документы:
        </p>
        <ul>
          <li>паспорт гражданина РФ; </li>
          <li>свидетельство о прохождении профессиональной подготовки</li>
        </ul>
        <p>Стоимость курсов:</p>
        <ul>
          <li>4 разряд - 3500 рублей (8 часов)</li>
          <li>5 разряд - 4500 рублей (16 часов)</li>
          <li>6 разряд - 5000 рублей (20 часов)</li>
        </ul>
        <p>
          Программы курсов специально подготовлены для специалистов, которым
          необходимо продлить удостоверение частного охранника.
        </p>
        В процессе обучения слушатели узнают:
        <li>
          об изменениях основных законов, нормативов и актов, регламентирующих
          деятельность частных охранных организаций;
        </li>
        <li>об условиях и порядке применения специальных средств;</li>
        <li>
          о мерах безопасности при обращении с гражданским и служебным оружием
          (в зависимости от разряда);
        </li>
        <li>
          как пользоваться техническими средствами охраны и пожаротушения,
          оказывать первую медицинскую помощь, применять приемы самозащиты и
          использовать специальные средства либо гражданское и служебное оружие
          (в зависимости от разряда).{" "}
        </li>
        <p>
          По окончании учебного курса слушателям школы охранников выдается
          свидетельство установленного образца. Свидетельство является одним из
          оснований для продления удостоверения частного охранника.
        </p>
        <p>
          Режим работы Центра обучения:
          <br />
          понедельник - пятница с 09:00 до 18:00, без обеда
          <br />
          суббота, воскресенье и праздничные дни - выходные.
        </p>
        <p>
          Расскажем подробнее о том, что изучается на курсах повышения
          квалификации для специалистов разных разрядов.
        </p>
        <h2>Повышение квалификации охранника 4 разряда</h2>
        <p>В программу входит:</p>
        <ul>
          <li>тактическая подготовка;</li>
          <li>правовая подготовка;</li>
          <li>изучение методов оказания первой помощи;</li>
          <li>техническая подготовка;</li>
          <li>
            совершенствование навыков использования специальных средств:
            резиновая палка, наручники и так далее.
          </li>
        </ul>
        <h2>Повышение квалификации охранника 5 разряда</h2>
        <p>В программу входит:</p>
        <ul>
          <li>правовая подготовка;</li>
          <li>тактико-специальная подготовка;</li>
          <li>огневая подготовка;</li>
          <li>совершенствование навыков использования специальных средств;</li>
          <li>техническая подготовка;</li>
          <li>медицинская подготовка;</li>
          <li>подготовка в области психологии;</li>
          <li>специальная физическая подготовка.</li>
        </ul>
        <h2>Повышение квалификации охранника 6 разряда</h2>
        <p>В программу входит:</p>
        <ul>
          <li>изучение тактики и методов охраны имущества;</li>
          <li>совершенствование навыков обращения со спецсредствами;</li>
          <li>совершенствование технической подготовки;</li>
          <li>занятия по оказанию первой помощи;</li>
          <li>специальная огневая подготовка;</li>
          <li>психологическое обучение;</li>
          <li>правовая подготовка.</li>
        </ul>
        <p>
          Более подробную информацию вы можете узнать по телефону:{" "}
          <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
            {process.env.REACT_APP_TELEPHONE_USER}
          </a>
        </p>
      </div>
    </div>
  );
}

function RefreshTraining(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default RefreshTraining;
