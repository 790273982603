import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { LinkContainer } from "react-router-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Dropdown.css";

function Dropdown({ dropdown: { title, route, items } }) {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  return (
    <NavDropdown
      show={show}
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
      title={title}
      menuVariant="dark"
      className="mx-1 my-0"
      style={{
        position: "relative",
        zIndex: "1025",
      }}
    >
      <LinkContainer key={uuidv4()} role="button" to={route}>
        <NavDropdown.Item className="fs-5">{title}</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Divider />
      {items.map((item) =>
        item.href ? (
          <NavDropdown.Item
            key={uuidv4()}
            href={item.link}
            target="_blank"
            className="text-wrap"
          >
            {item.title}
          </NavDropdown.Item>
        ) : (
          <LinkContainer key={uuidv4()} to={item.link}>
            <NavDropdown.Item className="text-wrap">
              {item.title}
            </NavDropdown.Item>
          </LinkContainer>
        )
      )}
    </NavDropdown>
  );
}

export default Dropdown;
