import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { v4 as uuidv4 } from "uuid";

function MediaCarousel({ photos }) {
  return (
    <Carousel fade className="w-100">
      {photos.map((photo) => (
        <Carousel.Item key={uuidv4()}>
          <img
            className="d-block img-fluid rounded-3"
            src={photo.src}
            alt={photo.title}
          />
          <Carousel.Caption>
            <h3>{photo.title}</h3>
            <p>{photo.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default MediaCarousel;
