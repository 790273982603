import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Продление разрешения на оружие</h1>
      <div>
        <p>
          Согласно Закону «Об оружии» 150-ФЗ от 13.11.1996 года для продления
          разрешения на хранение и ношение оружия самообороны необходим акт
          проверки знаний безопасного обращения с оружием. Комиссия по
          проведению проверки знаний функционирует на базе Учебного центра
          &laquo;Собрат&raquo;. В ходе проверки граждане проходят тестирование в
          компьютерном классе и выполняют стрелковые упражнения в тире. Подобную
          процедуру необходимо проходить раз в пять лет.
        </p>

        <p>
          Для прохождения проверки знаний по безопасному обращению с оружием
          необходимо предоставить:
        </p>

        <ul>
          <li>копию паспорта гражданина РФ;</li>
          <li>
            копию разрешения на оружие ограниченного поражения которое
            продлеваем;
          </li>
          <li>фотографии 3см x 4см матовые, 2 штуки.</li>
        </ul>
        <h2>Продление разрешения на охотничье оружие</h2>
        <p>
          Охотничье оружие - это совокупность моделей и марок, предназначенных
          для добывания дичи в спортивных, промысловых или любительских целях.
        </p>
        <p>
          Для продления лицензии на охотничье оружие нужны следующие документы:
        </p>
        <ul>
          <li>паспорт гражданина РФ;</li>
          <li>старое разрешение на оружие</li>
          <li>
            медицинского заключения об отсутствии противопоказаний к владению
            оружием (справка о результатах химико-токсикологических исследований
            (форма 454/у-06)
          </li>
          <li>
            медицинское заключение по форме 003-О/у (от нарколога по месту
            прописки)
          </li>
          <li>
            медицинское заключение об отсутствии медицинских противопоказаний к
            владению оружием по форме 002-О/у
          </li>
          <li>охот билет единого федерального образца</li>
          <li>
            акт проверки условий хранения оружия по месту жительства (после
            подачи документов, инспектор ЛРР лично, либо участковый по запросу
            инспектора ЛРР, выезжают на адрес по месту хранения оружия для
            составления акта)
          </li>
          <li>квитанция об оплате государственной пошлины</li>
          <li>фотографии 3х4 матовые</li>
        </ul>
        <p>
          Проверка документов составляет порядка 30 дней. Помимо стандартных
          процедур многих граждан проверяют на наличие судимостей.
        </p>
        <h2>Продление разрешения на гладкоствольное оружие</h2>
        <p>
          Оружие с гладкими каналами называется гладкоствольным. Чтобы получить
          разрешение на его использование, нужно получить охот билет. Его
          оформление занимает неделю.
        </p>
        <p>
          При получении разрешения сдается экзамен на умение владеть оружием.
        </p>
        <h2> Продление разрешения на нарезное оружие</h2>
        <p>
          Данная процедура осуществляется точно так же, как и для прочих видов
          оружия. Однако есть одна особенность - гражданин, который хочет
          владеть нарезным оружием, должен иметь минимум пятилетний стаж ношения
          гладкоствольного.
        </p>
        <p>
          Напомним, что особенность нарезного оружия в том, что оно имеет
          винтовые нарезы в канале ствола, что придает пуле вращательное
          движение. Это обеспечивает устойчивость снаряда на траектории и
          увеличивает дальность его полета.
        </p>
        <h2>Продление разрешения на травматическое оружие</h2>
        <p>
          Лицензия на травматическое оружие действует 5 лет, затем нужно ее
          продлить. Для этого в ЛРР требуется предоставить:
        </p>
        <ul>
          <li>паспорт гражданина РФ</li>
          <li>старая лицензия</li>
          <li>
            медицинского заключения об отсутствии противопоказаний к владению
            оружием (справка о результатах химико-токсикологических исследований
            (форма 454/у-06)
          </li>
          <li>
            медицинское заключение по форме 003-О/у (от нарколога по месту
            прописки)
          </li>
          <li>
            медицинское заключение об отсутствии медицинских противопоказаний к
            владению оружием по форме 002-О/у
          </li>
          <li>
            документа о прохождении проверки знаний и навыков безопасного
            обращения с оружием
          </li>
          <li>
            акт проверки условий хранения оружия по месту жительства (после
            подачи документов, инспектор ЛРР лично, либо участковый по запросу
            инспектора ЛРР, выезжают на адрес по месту хранения оружия для
            составления акта)
          </li>
          <li>квитанция об оплате государственной пошлины</li>
          <li>фотографии 3х4 матовые</li>
        </ul>
        <p></p>
        <p>
          Все подробности о продлении разрешения на вышеперечисленные виды
          оружия вы можете узнать по телефону:{" "}
          <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
            {process.env.REACT_APP_TELEPHONE_USER}
          </a>
        </p>
      </div>
    </div>
  );
}

function GunPermitRenewal(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default GunPermitRenewal;
