export const sidebarLinks = {
  title: "Безопасное обращение с оружием",
  links: [
    {
      value: "/safe-gun-handling/safe-gun-handling-study",
      title: "Обучение безопасному обращению с оружием",
    },
    {
      value: "/safe-gun-handling/license-for-civil-gun",
      title: "Лицензия на приобритение гражданского оружия",
    },
    {
      value: "/safe-gun-handling/gun-permit",
      title: "Разрешение на оружие",
    },
    {
      value: "/safe-gun-handling/renewal-gun-permit",
      title: "Продление разрешения на оружие",
    },
    {
      value: "/safe-gun-handling/shooting-range",
      title: "Стрелковый тир",
    },
    {
      value: "/safe-gun-handling/medical-board",
      title: "Медицинская комиссия",
    },
  ],
};
