import React from "react";
import "./Footer.css";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import footerLogo from "./logo-footer.png";

function Footer(props) {
  return (
    <footer className="container d-flex flex-wrap justify-content-center align-items-center py-3 mt-0 border-top bg-white mb-0">
      <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
        <p className="mb-0 text-center">Свяжитесь с нами:</p>

        <a
          href={"tel:" + process.env.REACT_APP_TELEPHONE_HREF}
          className="nav-link my-0 text-center"
        >
          {process.env.REACT_APP_TELEPHONE_USER}
        </a>
        <a
          href={"mailto:" + process.env.REACT_APP_EMAIL}
          className="nav-link my-0 text-center p-0"
        >
          {process.env.REACT_APP_EMAIL}
        </a>
      </div>

      <LinkContainer
        to="/"
        className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
      >
        <Navbar.Brand>
          <img
            src={footerLogo}
            className="bi me-2 mobile-none"
            width="110"
            height="110"
            alt="Логотип &laquo;Собрат&raquo;"
          />
        </Navbar.Brand>
      </LinkContainer>

      <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
        <p className="mb-0 text-center moblie-margin">
          {process.env.REACT_APP_ADRESS}
        </p>
        <LinkContainer to="/contacts">
          <Nav.Link>
            <p className="nav-link my-0 text-center p-0">Как добраться?</p>
          </Nav.Link>
        </LinkContainer>
        <p className="mb-0 text-muted text-center">
          © 2021-{new Date().getFullYear()} Учебный центр &laquo;Собрат&raquo;
        </p>
      </div>
    </footer>
  );
}

export default Footer;
