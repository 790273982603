import React from "react";
import * as Scroll from "react-scroll";
import ContactCards from "./ContactCards";
import Map from "../Map/Map";
import "./Contacts.css";

function Contacts(props) {
  return (
    <div className="d-flex flex-column align-center justify-content-center p-4">
      <ContactCards />
      <div className="container">
        <h4>Режим работы</h4>
        <p>
          Наш учебный центр работает с понедельника по пятницу с 09:00 до 18:00.
        </p>
      </div>
      <div className="container mt-2">
        <Scroll.Element name="route">
          <h4>Как до нас добраться?</h4>
        </Scroll.Element>
        <p>
          Мы располагаемся по адресу: Санкт-Петербург, улица Салова, 37А, 4
          этаж.
        </p>
        <p>
          Комфортнее всего до нас можно добраться на автомобиле. Рядом с нашим
          центорм есть бесплатная парковка для клиентов.
        </p>
        <p>
          Если вы добираетесь на общественном транспорте, то от станции метро
          Бухарестская можно воспользоваться 36 или 59 автобусом, примерное
          время в пути - 7 минут.
        </p>
        <p>
          Также от метро можно прогуляться пешком - займёт примерно 25 минут.
        </p>
      </div>
      <div className="contacts-map">
        <Map height={640} />
      </div>
    </div>
  );
}

export default Contacts;
