import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Обучение безопасному обращению с оружием</h1>
      <div>
        <p>
          Федеральный Закон от 13.12.96 ФЗ-150 "Об оружии" определил требования
          по порядку приобретения гражданского оружия. Граждане Российской
          Федерации в праве приобрести до двух единиц оружия ограниченного
          поражения для целей самообороны после прохождения обучения безопасному
          обращением оружием и получения соответствующих навыков.
        </p>
        <p>
          Для получения лицензии на приобретение оружия гражданин обязан
          предоставить в орган внутренних дел заявление установленной формы,
          документ удостоверяющий гражданство РФ, документы о прохождении
          соответствующей подготовки и периодической проверки знаний правил
          безопасного обращения с оружием, медицинское заключение об отсутствии
          противопоказаний к владению оружием. Кроме того, гражданин обязан по
          месту жительства оборудовать место для хранения оружия, исключая
          возможность доступа посторонних лиц.
        </p>
        <p>
          В выдаче лицензии на приобретение оружия может быть отказано при
          следующих обстоятельствах:
        </p>
        <ul>
          <li>гражданам, не достигшим 21 летнего возраста;</li>
          <li>
            гражданам, не представившим медицинское заключение об отсутствии
            противопоказаний к владению оружием;
          </li>
          <li>гражданам, имеющим неснятую или непогашенную судимость;</li>
          <li>гражданам, отбывающим наказание за совершенное преступление;</li>
          <li>
            гражданам, совершившим повторно в течение года административное
            правонарушение, посягающее на общественный правопорядок или право
            управления;
          </li>
          <li>гражданам, не имеющим постоянного места жительства;</li>
          <li>
            гражданам, не представившим в органы внутренних дел документ о
            прохождении соответствующей подготовки;
          </li>
          <li>
            гражданам, лишенным по решению суда права на приобретение оружия;
          </li>
          <li>
            гражданам, состоящим на учете в учреждениях здравоохранения по
            поводу психического заболевания, алкоголизма и наркомании.
          </li>
        </ul>
      </div>
    </div>
  );
}

function Study(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default Study;
