import React from "react";
import { Link } from "react-router-dom";
import Map from "../Map/Map";

function Sidebar(props) {
  return (
    <div className="col-lg-4">
      <div className="card mb-4">
        <div className="card-header">Об организации</div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <ul className="list-unstyled mb-0">
                <Link
                  to="../resolution-of-russian-federation"
                  className="text-decoration-none"
                >
                  <li role="link">Постановления правительства РФ</li>
                </Link>
                <Link to="../orders" className="text-decoration-none">
                  <li role="link">Приказы</li>
                </Link>
                <Link to="../laws-of-russia" className="text-decoration-none">
                  <li role="link">Законы РФ</li>
                </Link>
                <a
                  href="/uploads/docs/school-license.pdf"
                  className="text-decoration-none"
                  target="_blank"
                >
                  <li role="link">Лицензия на образовательную деятельность</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header">Ждём вас в нашем центре</div>
        <div className="card-body">
          <Map height={300} />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
