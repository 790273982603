import React from "react";
import * as Scroll from "react-scroll";

function ContactCards(props) {
  return (
    <section className="contact-section my-3">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5">
          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card h-100">
              <div className="card-body text-center">
                <svg
                  className="svg-inline--fa fa-map-location-dot text-primary mb-2"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="map-location-dot"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1200 0 3000 650"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M408 120C408 174.6 334.9 271.9 302.8 311.1C295.1 321.6 280.9 321.6 273.2 311.1C241.1 271.9 168 174.6 168 120C168 53.73 221.7 0 288 0C354.3 0 408 53.73 408 120zM288 152C310.1 152 328 134.1 328 112C328 89.91 310.1 72 288 72C265.9 72 248 89.91 248 112C248 134.1 265.9 152 288 152zM425.6 179.8C426.1 178.6 426.6 177.4 427.1 176.1L543.1 129.7C558.9 123.4 576 135 576 152V422.8C576 432.6 570 441.4 560.9 445.1L416 503V200.4C419.5 193.5 422.7 186.7 425.6 179.8zM150.4 179.8C153.3 186.7 156.5 193.5 160 200.4V451.8L32.91 502.7C17.15 508.1 0 497.4 0 480.4V209.6C0 199.8 5.975 190.1 15.09 187.3L137.6 138.3C140 152.5 144.9 166.6 150.4 179.8H150.4zM327.8 331.1C341.7 314.6 363.5 286.3 384 255V504.3L192 449.4V255C212.5 286.3 234.3 314.6 248.2 331.1C268.7 357.6 307.3 357.6 327.8 331.1L327.8 331.1z"
                  ></path>
                </svg>

                <i className="fas fa-map-marked-alt text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">Адрес</h4>
                <hr className="my-4 mx-auto" />
                <div className="small text-black-50">
                  <Scroll.Link
                    to="route"
                    className="fs-5 stretched-link"
                    role="button"
                  >
                    Санкт-Петербург
                    <br />
                    улица Салова, 37А
                  </Scroll.Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card h-100">
              <div className="card-body text-center">
                <svg
                  className="svg-inline--fa fa-envelope text-primary mb-2"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1200 0 3000 650"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
                  ></path>
                </svg>
                <i className="fas fa-envelope text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">Почта</h4>
                <hr className="my-4 mx-auto" />

                <a
                  href={"mailto:" + process.env.REACT_APP_EMAIL}
                  className="fs-5 stretched-link"
                >
                  {process.env.REACT_APP_EMAIL}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card h-100">
              <div className="card-body text-center">
                <svg
                  className="svg-inline--fa fa-mobile-screen-button text-primary mb-2"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="mobile-screen-button"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1200 0 3000 650"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M304 0h-224c-35.35 0-64 28.65-64 64v384c0 35.35 28.65 64 64 64h224c35.35 0 64-28.65 64-64V64C368 28.65 339.3 0 304 0zM192 480c-17.75 0-32-14.25-32-32s14.25-32 32-32s32 14.25 32 32S209.8 480 192 480zM304 64v320h-224V64H304z"
                  ></path>
                </svg>
                <i className="fas fa-mobile-alt text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">Телефон</h4>
                <hr className="my-4 mx-auto" />
                <a
                  href={"tel:" + process.env.REACT_APP_TELEPHONE_USER}
                  className="fs-5 stretched-link"
                >
                  {process.env.REACT_APP_TELEPHONE_USER}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactCards;
