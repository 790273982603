import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Медицинская комиссия</h1>

      <div>
        <p>
          Медицинская справка (форма 002) нужна для получении лицензии на
          оружие, его ношение и использование. Она в обязательном порядке
          оформляется, к примеру, охотниками и охранниками.
        </p>
        <p>
          Данный документ подтверждает, что у человека нет отклонений,
          препятствующих ношению оружия. Справку можно получить в поликлинике по
          месту жительства, комиссия состоит из нескольких врачей: нарколога,
          психиатра, терапевта и офтальмолога. Они пишут заключение о состоянии
          здоровья пациента (физиологическом и психологическом). Все результаты
          обследования заносятся в специальный бланк государственного стандарта,
          на нем должны быть подписи всех врачей, подтвержденные печатями.
          Только в этом случае справка имеет юридическую силу.
        </p>
        <p>
          Противопоказаниями для ношения оружия являются такие заболевания, как:
          эпилепсия, наркомания, алкоголизм, токсикомания и ряд других, а также
          проблемы со зрением.
        </p>
      </div>
    </div>
  );
}

function MedicalBoard(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default MedicalBoard;
