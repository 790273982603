import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

function Law({ laws }) {
  return (
    <ListGroup variant="flush">
      {laws.map((law) => (
        <ListGroup.Item key={law.id} className="my-2">
          <a
            href={law.src}
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
          >
            {law.title}
          </a>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default Law;
