import React from "react";
import SamplePage from "../SamplePage/SamplePage";
import { sidebarLinks } from "./sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Школа частных охранников</h1>
      Учебный центр &laquo;Собрат&raquo; проводит:
      <ul>
        <li>
          обучение и повышение квалификации частных охранников, а также
          юридических лиц с особыми уставными задачами;
        </li>
        <li>
          организацию квалификационных экзаменов и периодических проверок;
        </li>
        <li>
          подготовку документов на проведение обязательной дактилоскопической
          регистрации;
        </li>
        <li>
          подготовку документов, необходимых для получения и продления
          удостоверения частного охранника.
        </li>
      </ul>
      Нашим учебным центром осуществляется профессиональная подготовка
      охранников. В процессе обучения слушатели проходят курсы правовой и
      медицинской подготовки, учатся применению специальных средств охраны и
      осваивают навыки обращения со служебным оружием. Мы готовим специалистов
      4, 5 и 6 разрядов. Все выпускники школы сдают квалификационный экзамен. По
      итогам обучения выдается свидетельство, которое является необходимым
      документом для получения удостоверения частного охранника.
      <br />
      <br />
      <div>
        <h2>Часто задаваемые вопросы</h2>
        <p>
          Ищете, где выучиться на охранника? Если вы хотите стать настоящим
          профессионалом, важно знать: школа охранников должна иметь лицензию на
          образовательную деятельность и обучать по утверждённой методике. Ниже
          вы найдёте ответы на самые популярные вопросы и узнаете, как быстро и
          просто получить удостоверение.
        </p>

        <div>
          <h3>Как получить удостоверение частного охранника?</h3>
          <div>
            <div>
              <p>
                Частный охранник должен иметь удостоверение, подтверждающее его
                профессионализм. Без лицензии невозможно устроиться на работу в
                организацию, которая предоставляет услуги по охране объектов и
                частных лиц.
              </p>
              <p>Чтобы получить документ, нужно:</p>
              <ul>
                <li>обучиться профессии в специальной организации;</li>
                <li>сдать квалификационный экзамен;</li>
                <li>пройти дактилоскопическую регистрацию;</li>
                <li>получить медицинскую справку формы 002 и 454/у-06;</li>
                <li>оплатить госпошлину;</li>
                <li>подать личное дело в ЦЛРР.</li>
              </ul>
              <p>
                Не забудьте подготовить документы для регистрации личного дела в
                Центре лицензионно-разрешительной работы, где вы собираетесь
                получить лицензию охранника.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3>Как получить лицензию охранника?</h3>
          <div>
            <div>
              <p>
                Чтобы получить официальное разрешение на профессиональную
                охранную деятельность, необходимо:
              </p>
              <ul>
                <li>
                  пройти медкомиссию и получить заключение по форме 002-О/У;
                </li>
                <li>пройти профессиональную подготовку;</li>
                <li>сдать квалификационный экзамен;</li>
                <li>
                  подготовить все необходимые документы (паспорт, справка о
                  дактилоскопической регистрации, трудовая книжка — копия,
                  фотографии).
                </li>
              </ul>
              <p>
                Как получить лицензию охранника в СПб по выгодной цене? В нашей
                школе охранников вы пройдёте необходимую подготовку, которая
                позволит вам подать заявление на получение удостоверения.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3>Как продлить лицензию охранника?</h3>
          <div>
            <div>
              <p>Закончилось удостоверение частного охранника: что делать?</p>
              <p>
                Чтобы продлить лицензию частного охранника, нужно пройти
                обучение в ЧОУ ДПО УЦ «Собрат» по программе повышения
                квалификации, сдать квалификационный экзамен и предоставить все
                документы, необходимые для формирования личного дела.
              </p>
              <p>Как продлить удостоверение охранника в период коронавируса?</p>
              <p>
                Пройдите обучение по программе повышения квалификации в нашей
                школе охранников и сдайте экзамен. Оплатить госпошлину и подать
                заявление на продление документов вы можете в электронном виде
                через Госуслуги или воспользоваться нашими услугами.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3>Вопросы экзамена охранника 4 разряда</h3>
          <div>
            <div>
              <p>
                Каждый курс обучения завершается теоретическим и практическим
                экзаменом. Вопросы и варианты ответов утверждены Главным
                управлением государственного контроля и
                лицензионно-разрешительной работы Федеральной службы войск
                национальной гвардии Российской Федерации.
              </p>
              <p>
                Для подтверждения теоретических знаний необходимо ответить на
                вопросы по правовой, огневой и тактико-специальной подготовке,
                использованию специальных средств, оказанию первой помощи и
                противодействию терроризму. По результатам экзамена выдаётся
                свидетельство, необходимое для получения удостоверения.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3>Размер фото на удостоверение охранника?</h3>
          <div>
            <div>
              <p>
                Если вы впервые получаете удостоверение или сдаёте документы для
                продления лицензии охранника 4 разряда, не забудьте принести
                фотографии. В соответствии с перечнем нормативных правовых
                актов, регулирующих деятельность органов внутренних дел,
                требования к фотографиям установлены следующие:
              </p>
              <ul>
                <li>размер фото должен быть 4см x 6см;</li>
                <li>матовые;</li>
                <li>цветные;</li>
                <li>в количестве 4 штук.</li>
              </ul>
              <p>
                Фотографии необходимо предоставить после обучения и успешно
                сданного квалификационного экзамена.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3>Сколько стоит лицензия охранника?</h3>
          <div>
            <div>
              <p>
                Без лицензии и присвоения категории получить работу охранником
                невозможно. Первый шаг — школа частных охранников в Учебном
                центре &laquo;Собрат&raquo;. Здесь не только дают знания о
                правовой подготовке, но и учат обращаться с оружием и
                специальными средствами, оказывать первую помощь. И самое
                главное: помогут решить все вопросы по подготовке документов и
                получению удостоверения, чтобы вам не пришлось тратить на это
                время.
              </p>
              <p>
                Хотите узнать, как сделать лицензию охранника в СПб по выгодной
                цене? Звоните в наш центр.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <b>
          Перечень вопросов для проверки теоретических знаний, которые
          указываются в карточках опроса с вариантами ответов{" "}
        </b>
        <a
          title="вопросы для охранника 4,5,6 разряда 2019.doc"
          rel="nofollow"
          href="/uploads/docs/Perechen_voprosov_dlya_CHOO.doc"
          target="_blank"
        >
          <b>можно скачать здесь</b>
        </a>
      </div>
      <br />
      Подробности вы можете получить у специалистов нашего Центра по телефону
      <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
        {process.env.REACT_APP_TELEPHONE_USER}
      </a>
    </div>
  );
}

function GuardsSchools(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default GuardsSchools;
