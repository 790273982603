import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

function Resolution({ resolutions }) {
  return (
    <ListGroup variant="flush">
      {resolutions.map((res) => (
        <ListGroup.Item key={res.id} className="my-2">
          <a
            href={res.src}
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
          >
            {res.title}
          </a>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default Resolution;
