import React from "react";
import Sidebar from "./Sidebar";
import MainContainer from "./MainContainer";

function SamplePage({ TargetComponent, sidebarLinks }) {
  return (
    <div className="d-flex flex-row container mt-3">
      <div className="row">
        <MainContainer TargetComponent={TargetComponent} />
        <Sidebar sidebarLinks={sidebarLinks} />
      </div>
    </div>
  );
}

export default SamplePage;
