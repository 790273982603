import React from "react";
import Sidebar from "./Sidebar";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

function Organisation(props) {
  return (
    <div className="d-flex flex-column container my-4">
      <h1 className="mb-3">Сведения об Учебном центре &laquo;Собрат&raquo;</h1>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>1. Сведения об организации</Accordion.Header>
          <Accordion.Body>
            <Table striped bordered>
              <tbody>
                <tr>
                  <td>Полное наименование организации</td>
                  <td>
                    Частное образовательное учреждение дополнительного
                    профессионального образования Учебный центр
                    &laquo;Собрат&raquo;
                  </td>
                </tr>
                <tr>
                  <td>Сокращенное наименование организации</td>
                  <td>ЧОУ ДПО УЦ &laquo;Собрат&raquo;</td>
                </tr>
                <tr>
                  <td>Юридический адрес</td>
                  <td>
                    192102, Россия, г. Санкт-Петербург, улица Салова. д. 37,
                    литера А, пом. 15Н
                  </td>
                </tr>
                <tr>
                  <td>Фактический адрес</td>
                  <td>
                    192102, Россия, г. Санкт-Петербург, улица Салова. д. 37,
                    литера А, пом. 15Н
                  </td>
                </tr>
                <tr>
                  <td>Адрес осуществления образовательной деятельности</td>
                  <td>
                    192102, Россия, г. Санкт-Петербург, улица Салова. д. 37,
                    литера А, пом. 15Н
                  </td>
                </tr>
                <tr>
                  <td>Дата регистрации организации и ОГРН</td>
                  <td>
                    8 сентября 2021 г.
                    <br />
                    ОГРН 1217800138610
                  </td>
                </tr>
                <tr>
                  <td>Учередитель</td>
                  <td>Езопихин Никита Игоревич</td>
                </tr>
                <tr>
                  <td>Телефон</td>
                  <td>
                    <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
                      {process.env.REACT_APP_TELEPHONE_USER}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>E-mail</td>
                  <td>
                    <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                      {process.env.REACT_APP_EMAIL}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>График работы</td>
                  <td>
                    <p>
                      Учебный отдел: с понедельника по пятницу с 09:00 до 18:00,
                      обед с 13-00 до 14-00.
                    </p>
                    <p>Продолжительность учебного года - 51 рабочая неделя.</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            2. Структура и органы управления организации
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Руководство текущей деятельностью Учебного центра
              &laquo;Собрат&raquo; осуществляет единоличный исполнительный орган
              - Генеральный директор, который избирается Общим собранием
              участников сроком на пять лет. В непосредственном подчинении
              Генерального директора - Заместители директора, Педагогические
              работники, Начальник отдела вооружения, Начальник по хозяйственной
              части, Главный бухгалтер. Данным лицам подчиняются остальные
              специалисты.
            </p>
            <p>
              В ЧОУ ДПО УЦ &laquo;Собрат&raquo; создано специальное структурное
              образовательное подразделение, Учебный отдел, для осуществления
              образовательного процесса. Руководство Учебным отделом
              осуществляет Генеральный директор.
            </p>
            <p>
              Учебный отдел находится по адресу осуществления образовательной
              деятельности. Педагогический совет является коллегиальным органом
              управления Организации для рассмотрения основных вопросов
              образовательного процесса.
            </p>
            <p>
              В Педагогический совет входят педагогические работники организации
              на срок действия трудового договора с Учреждением.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>3. Документы</Accordion.Header>
          <Accordion.Body>
            <Link to="/uploads/docs/school-license.pdf">
              Лицензия на осуществление образовательной деятельности
            </Link>
            <p>Программы для несовершеннолетних обучающихся не реализуются.</p>
            <p>Коллективный договор не заключался.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>4. Образование</Accordion.Header>
          <Accordion.Body>
            <p>
              ЧОУ ДПО УЦ &laquo;Собрат&raquo; реализует программы
              профессионального обучения и программы дополнительного
              профессионального образования.
            </p>
            <p>
              ЧОУ ДПО УЦ &laquo;Собрат&raquo; не реализует общеобразовательных
              программ.
            </p>
            <p>
              Реализуемые образовательные программы не требуют государственной
              аккредитации.
            </p>
            <p>
              1. Программа профессионального обучения - «Программа
              профессиональной подготовки частных охранников» реализуется в
              следующих вариантах:
              <br />
            </p>

            <p>
              <b>
                Профессиональная подготовка охранников 4-го разряда - 40 учебных
                часов:
              </b>
            </p>
            <p>
              1. Правовая подготовка
              <br />
              2. Тактико-специальная подготовка
              <br />
              3. Техническая подготовка
              <br />
              4. Психологическая подготовка
              <br />
              5. Использование специальных средств
              <br />
              6. Оказание первой помощи
              <br />
              7. Специальная физическая подготовка
              <br />
              8. Противодействие терроризму
              <br />
              9. Итоговая аттестация
            </p>
            <p>
              <b>
                Профессиональная подготовка охранников 5-го разряда - 60 учебных
                часов:
              </b>
            </p>
            <p>
              1. Правовая подготовка
              <br />
              2. Тактико-специальная подготовка
              <br />
              3. Техническая подготовка
              <br />
              4. Психологическая подготовка
              <br />
              5. Огневая подготовка
              <br />
              6. Использование специальных средств
              <br />
              7. Оказание первой помощи
              <br />
              8. Специальная физическая подготовка
              <br />
              9. Противодействие терроризму
              <br />
              10. Итоговая аттестация
            </p>
            <p>
              <b>
                Профессиональная подготовка охранников 6-го разряда - 80 учебных
                часов:
              </b>
            </p>
            <p>
              1. Правовая подготовка
              <br />
              2. Тактико-специальная подготовка
              <br />
              3. Техническая подготовка
              <br />
              4. Психологическая подготовка
              <br />
              5. Огневая подготовка
              <br />
              6. Использование специальных средств
              <br />
              7. Оказание первой помощи
              <br />
              8. Специальная физическая подготовка
              <br />
              9. Противодействие терроризму
              <br />
              10. Итоговая аттестация
            </p>
            <p>
              2. Программа профессионального обучения - «Программа по повышению
              квалификации частных охранников» реализуется в следующих
              вариантах:
              <br />
            </p>
            <p>
              <b>
                Повышение квалификации частных охранников 6-го разряда - 20
                учебных часов:
              </b>
            </p>

            <p>
              1. Правовая подготовка
              <br />
              2. Тактико-специальная подготовка
              <br />
              3. Техническая подготовка
              <br />
              4. Огневая подготовка
              <br />
              5. Использование специальных средств
              <br />
              6. Оказание первой помощи
              <br />
              7. Противодействие терроризму
              <br />
              8. Итоговая аттестация
            </p>

            <p>
              <b>
                Повышение квалификации частных охранников 5-го разряда - 16
                учебных часов:
              </b>
            </p>
            <p>
              1. Правовая подготовка
              <br />
              2. Тактико-специальная подготовка
              <br />
              3. Техническая подготовка
              <br />
              4. Огневая подготовка
              <br />
              5. Использование специальных средств
              <br />
              6. Оказание первой помощи
              <br />
              7. Противодействие терроризму
              <br />
              8. Итоговая аттестация
            </p>
            <p>
              <b>
                Повышение квалификации частных охранников 4-го разряда - 8
                учебных часов:
              </b>
            </p>
            <p>
              1. Правовая подготовка
              <br />
              2. Тактико-специальная подготовка
              <br />
              3. Техническая подготовка
              <br />
              4. Использование специальных средств
              <br />
              5. Оказание первой помощи
              <br />
              6. Противодействие терроризму
              <br />
              7. Итоговая аттестация
              <br />
            </p>
            <p>
              3. Дополнительная профессиональная программа - «Программа
              повышения квалификации руководителей частных охранных организаций,
              впервые назначаемых на должность» - 80 учебных часов:
            </p>
            <p>
              1. Правовые основы деятельности руководителя частной охранной
              организации
              <br />
              2. Основы управления (менеджмент) в частной охранной организации.
              <br />
              3. Деятельность руководителя частной охранной организации по
              организации оказания охранных услуг.
              <br />
              4. Трудовые отношения и охрана труда в частной охранной
              организации.
              <br />
              5. Организация охранных услуг с применением технических средств.
              <br />
              6. Взаимодействие частных охранных организаций с
              правоохранительными органами.
              <br />
              7. Итоговая аттестация
            </p>
            <p>
              4. Дополнительная профессиональная программа - «Программа
              повышения квалификации руководителей частных охранных организаций»
              - 20 учебных часов:
            </p>
            <p>
              1. Правовые основы деятельности руководителя частной охранной
              организации
              <br />
              2. Основы управления (менеджмент) в частной охранной организации.
              <br />
              3. Деятельность руководителя частной охранной организации по
              организации оказания охранных услуг.
              <br />
              4. Трудовые отношения и охрана труда в частной охранной
              организации.
              <br />
              5. Организация охранных услуг с применением технических средств.
              <br />
              6. Взаимодействие частных охранных организаций с
              правоохранительными органами.
              <br />
              7. Итоговая аттестация
            </p>
            <p>Форма обучения очная, согласно расписанию занятий.</p>
            <p>Обучение проходит на русском языке. </p>
            <p>
              Методические и иные документы, разработанные для обеспечения
              образовательного процесса находятся в учебных классах, доступ к
              материалам имеют все обучающиеся ипреподаватели.
            </p>
            <p>
              Электронное обучение и дистанционные образовательные технологии
              при реализации выше перечисленных программ не используются.
            </p>
            <p>
              ЧОУ ДПО УЦ &laquo;Собрат&raquo;, не имеет адаптированных
              образовательных программ в связи с тем, что специфика реализуемых
              программ не позволяет лицам, имеющим ограничения по состоянию
              здоровья, их осваивать.
            </p>
            <p>
              Места для приема за счет федерального бюджета, бюджетов субъектов
              РФ, местных бюджетов в ЧОУ ДПО УЦ &laquo;Собрат&raquo; не
              предусмотрены.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            5. Руководство и педагогический состав
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Генеральный директор - Ляпкало Владимир Олегович
              <br />
              Телефон:{" "}
              <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
                {process.env.REACT_APP_TELEPHONE_USER}
              </a>
              <br />
              E-mail:{" "}
              <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                {process.env.REACT_APP_EMAIL}
              </a>
            </p>
            <p>
              <Link to="/organisation/trainers">
                Сведения о педагогических работниках
              </Link>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            6. Материально-техническое обеспечение и оснащенность
            образовательного процесса
          </Accordion.Header>
          <Accordion.Body>
            <p>
              В штате ЧОУ ДПО УЦ &laquo;Собрат&raquo; профессиональные
              педагогические и высококвалифицированные сотрудники, обладающие
              знаниями современного законодательства и правоприменительной
              практики в сфере оборота оружия.
            </p>
            <p>
              Обучение по образовательной программе осуществляется в учебной
              аудитории общей вместимостью 16 учебных места, 3 стрелковых
              галереи.
            </p>
            <p>
              Учебная аудитория для качественного обучения оснащены проекционным
              оборудованием и наглядными плакатами. Для практических занятий
              имеются учебные пособия, различные макеты, технические средства.
            </p>
            <p>
              Практические занятия по отработке навыков стрельбы осуществляются
              в стрелковых галереях, на стрельбах используется гражданское
              оружие, предназначенное для использования гражданами РФ в целях
              самообороны, для занятий спортом и охотой, а также для культурных
              и образовательных целях.
            </p>
            <p>
              Сведения о доступе к информационным системам и
              информационно-телекоммуникационным сетям:
            </p>
            <p>
              В свободном доступе для обучающихся - 12 компьютеров, для учителей
              - 5 компьютеров. Все компьютеры ЧОУ ДПО УЦ &laquo;Собрат&raquo;
              соединены в единую локальную сеть, имеют выход в Интернет.
            </p>
            <p>
              При освоении дополнительной общеразвивающей программы организация
              использует обучающие видеофильмы, слайды.
            </p>
            <p>
              На сайте организации размещаются важные документы, касающиеся
              организации образовательного процесса.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>7. Платные образовательные услуги</Accordion.Header>
          <Accordion.Body>
            <p>
              <a href="/">
                ПОЛОЖЕНИЕ об оказании платных образовательных услугах
              </a>
            </p>
            <p>
              <a href="/">ПРИКАЗ о стоимости обучения на 2022 г.</a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            8. Финансово-хозяйственная деятельность
          </Accordion.Header>
          <Accordion.Body>
            <p>
              ЧОУ ДПО УЦ &laquo;Собрат&raquo; самостоятельно осуществляет
              финансово-хозяйственную деятельность. Оно имеет самостоятельный
              баланс и расчетный счет в банковских организациях.
            </p>
            <p>
              Источниками формирования имущества Организации в денежной и иных
              формах являются доходы, полученные от реализации образовательных
              услуги от других видов деятельности, осуществляемой Организацией
              самостоятельно.
            </p>
            <p>
              Поступления в денежном выражении вносятся на расчетный счет
              Организации.
            </p>
            <p>
              Сделки и иные юридические акты от имени организации совершаются
              Генеральным директором в пределах компетенции, определенной
              Уставом.
            </p>
            <p>
              Оперативный, бухгалтерский и статистический учет и отчетность
              Организации осуществляются в порядке, предусмотренном
              законодательством Российской Федерации.
            </p>
            <p>
              Финансовое обеспечение образовательной деятельности за счет
              бюджетных ассигнований федерального бюджета, бюджетов субъектов
              РФ, местных бюджетов не осуществлялось.
            </p>
            <p>
              Финансовое обеспечение образовательной деятельности осуществлялось
              по договорам об образовании за счет средств физических и (или)
              юридических лиц.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            9. Вакантные места для приема (перевода)
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Количество вакантных мест за счет федерального, субъектов РФ,
              местного бюджета - не предусмотрено.
            </p>
            <p>
              Места, финансируемые по договорам об образовании за счет средств
              физических и (или) юридических лиц неограниченно.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>10. Доступная среда</Accordion.Header>
          <Accordion.Body>
            <p>
              ЧОУ ДПО УЦ &laquo;Собрат&raquo; обеспечивает доступ в здание
              инвалидов и лиц ОВЗ.
            </p>
            <p>
              В организации созданы условия для нахождения лиц с ограниченными
              возможностями:
            </p>
            <ul>
              <li>съемный пандус;</li>
              <li>кнопка звонка для вызова сотрудника тира;</li>
            </ul>

            <p>
              ЧОУ ДПО УЦ &laquo;Собрат&raquo; реализует следующую программу:
            </p>
            <p>
              - «Подготовка лиц в целях изучения правил безопасного обращения с
              оружием и приобретения навыков безопасного обращения с оружием».
            </p>
            <p>
              Лица, приобретающие оружие, в соответствии с требованиями ст. 13
              ФЗ «Об оружии» от 13.12.1996 г. № 150-ФЗ должны предоставить мед.
              заключение об отсутствии медицинских противопоказаний к владению
              оружием. Приказ Минздрава РФ от 11 09.2000 г. № 344 «О медицинском
              освидетельствовании граждан для выдачи лицензии на право
              приобретения оружия».
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>11. Международное сотрудничество</Accordion.Header>
          <Accordion.Body>
            <p>
              ЧОУ ДПО УЦ &laquo;Собрат&raquo; не заключает международного
              сотрудничества, т.к. реализуемая программа может быть только для
              граждан РФ.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default Organisation;
