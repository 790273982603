import React from "react";
import SamplePage from "../SamplePage/SamplePage";
import { sidebarLinks } from "./sidebarLinks";
import Table from "react-bootstrap/Table";

function TargetComponent(props) {
  return (
    <div>
      <h1>Безопасное обращение с оружием</h1>
      <div>
        <p>
          На базе Учебного центра &laquo;Собрат&raquo; проводится обучение
          обращению с оружием, проверка знаний граждан, а также подготовка
          необходимых документов для получения следующих лицензий:
        </p>
        <ul>
          <li>
            на приобретение охотничьего огнестрельного гладкоствольного
            длинноствольного оружия (сроком на 6 месяцев);
          </li>
          <li>
            на приобретение газового оружия для целей самообороны (сроком на 5
            лет);
          </li>
          <li>
            на приобретение огнестрельного оружия ограниченного поражения
            (сроком на 6 месяцев).
          </li>
        </ul>
        <p></p>
        <h2>Проверка знаний безопасного обращения с оружием</h2>
        <p>
          Данная процедура включает в себя проверку теоретических знаний и
          практических навыков испытуемого. Проводится выполнение упражнений с
          использованием гражданского оружия. Те граждане, которые проходят
          периодическую проверку, могут использовать оружие и патроны,
          принадлежащие им на законном основании.
        </p>
        <p>
          Проверку должны проходить лица, впервые приобретающие оружие
          самообороны. Те, кто уже имеет лицензию на хранение или на хранение и
          ношение оружия, проходят проверку раз в пять лет (в соответствии со
          статьей 13 ФЗ «Об оружии»).
        </p>
        <p>
          <b>
            Согласно Приложению к приказу
            <br />
            Федеральной службы войск национальной гвардии
            <br />
            Российской Федерации от 26.12.2018 № 661
          </b>
        </p>
        <p>
          Перечень вопросов для проверки теоретических знаний, которые
          указываются в карточках опроса{" "}
          <a
            title="Вопросы к тестированию по БО"
            href="/uploads/docs/voprosy_i_otvety_dlya_sdachi_ekzamena_na_grazhdanskoe_oruzhie_2021.pdf"
            target="_blank"
          >
            можно скачать по ссылке
          </a>
        </p>
        <h2>Курсы владения оружием</h2>
        <p>
          Обучение на курсах проводится в соответствии с программой подготовки
          лиц в целях изучения и приобретения навыков безопасного обращения с
          оружием, в объеме 6 часов.
        </p>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Разделы курса</th>
              <th>Количество часов</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Правовая подготовка</td>
              <td>1,4</td>
            </tr>
            <tr>
              <td>Огневая подготовка</td>
              <td>2,6</td>
            </tr>
            <tr>
              <td>Комплексный экзамен</td>
              <td>2</td>
            </tr>
          </tbody>
        </Table>
        <h3>ПЕРВИЧНОЕ ОБУЧЕНИЕ</h3>
        <p>
          <b>Необходимые документы:</b>
        </p>
        <ul>
          <li>паспорт РФ;</li>
          <li>2 фото размером 3х4 см матовые;</li>
          <li>мед. справка формы 002-О/у*;</li>
          <li>мед. справка формы 003-О/у*;</li>
          <li>справка об отсутствии судимости*.</li>
        </ul>
        <p>
          <b>Примечание:</b>
        </p>
        <ol>
          <li>
            Медицинскую справку формы 002-О/у можно получить по месту
            регистрации в районной поликлинике, либо в коммерческих медицинских
            организациях, после прохождения нарколога и психиатра.
          </li>
          <li>
            Медицинскую справку формы 003-О/у выдается по месту жительства,
            после химикотоксикологического анализа и осмотра у нарколога.
          </li>
          <li>
            Справку от психиатра оформляют по месту вашей регистрации в районной
            поликлинике.
          </li>
          <li>
            Справку об отсутствие судимости, можно получить на сайте Госуслуг.
          </li>
        </ol>
        <p>
          *В соответствии с подпунктом ж(4) пункта 15 Главы V ПРАВИЛА оборота
          гражданского и служебного оружия и патронов к нему на территории
          Российской Федерации Постановление Правительства РФ от 21.07.1998 г. №
          814 и с пунктами 2-4 и 9 части двадцатой ст. 13 ФЗ «Об оружии» от
          13.12.1996 г.
        </p>
        <h3>ПРОДЛЕНИЕ ЛИЦЕНЗИИ</h3>
        <p>
          <b>Необходимые документы:</b>
        </p>
        <ul>
          <li>паспорт РФ;</li>
          <li>действующее разрешение на хранение и ношение ОООП;</li>
          <li>2 фото размером 3х4 см матовые;</li>
        </ul>
        <p>
          <b>
            Если срок разрешения истек к моменту экзамена, необходимо
            предоставить:
          </b>
        </p>
        <ul>
          <li>мед. справка формы 002-О/у*;</li>
          <li>мед. справка формы 003-О/у*;</li>
          <li>справка об отсутствии судимости*.</li>
        </ul>
      </div>
    </div>
  );
}

function SafeGunHandling(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default SafeGunHandling;
