import React from "react";
import { Link } from "react-router-dom";
import Map from "../Map/Map";

function Sidebar(props) {
  return (
    <div className="col-lg-4">
      <div className="card mb-4">
        <div className="card-header">Об организации</div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6">
              <ul className="list-unstyled mb-0 ">
                <Link to="../trainers" className="text-decoration-none">
                  <li role="link">Тренеры</li>
                </Link>
                <Link to="../price-list" className="text-decoration-none">
                  <li role="link">Прайс-лист</li>
                </Link>
                <Link to="../media" className="text-decoration-none">
                  <li role="link">Галерея</li>
                </Link>
                <Link to="../about" className="text-decoration-none">
                  <li role="link">О центре</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header">Ждём вас в нашем центре</div>
        <div className="card-body">
          <Map height={300} />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
