import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Разрешение на оружие</h1>
      <div>
        <p>
          Лицензия на приобретение всех видов оружия (кроме газового) действует
          6 месяцев. В течение данного промежутка времени гражданин вправе
          приобрести оружие в оружейных магазинах, переоформить на себя оружие,
          находящееся в собственности другого человека, вступить в наследство.
          Далее в течение двух недель с момента приобретения гражданин обязан
          поставить оружие на учёт в районном ОЛРР по месту регистрации.
        </p>
        Подробная информация по телефону
        <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
          {process.env.REACT_APP_TELEPHONE_USER}
        </a>
      </div>
    </div>
  );
}

function GunPermit(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default GunPermit;
