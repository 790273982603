import React from "react";
import Sidebar from "../Sidebar";

function About(props) {
  return (
    <div className="d-flex flex-row container my-4">
      <div className="row">
        <div className="col-lg-8 mb-5 px-5">
          <h1>Учебный центр «Собрат» это:</h1>
          <ul>
            <li>Школа охранников </li>
            <li>Лицензионно-разрешительные работы</li>
            <li>Безопасное обращение с оружием</li>
            <li>Стрелковый тир</li>
          </ul>
          <p>
            ЧОУ ДПО УЦ «Собрат» приглашает всех желающих обучиться безопасному
            обращению с оружием, а также воспользоваться услугами Школы
            охранников. В Учебном центре ««Собрат»» каждый желающий получит
            следующий комплекс услуг:
          </p>
          <ul>
            <li>
              Обучение правилам и навыкам безопасного обращения с оружием;
            </li>
            <li>
              Подготовка документов для оформления лицензии на приобретение
              гражданского оружия;
            </li>
            <li>
              Проведение проверки знаний безопасного обращения с оружием с целью
              продления разрешения на оружие самообороны;
            </li>
            <li>
              Обучение в Школе охранников с дальнейшим получением профессии
              частного охранника всех разрядов;
            </li>
            <li> Организация квалификационного экзамена;</li>
            <li>
              Организация и подготовка к проведению периодических проверок
              частных охранников;
            </li>
            <li>
              Подготовка личного дела для получения удостоверения частного
              охранника;
            </li>
            <li> Фото на документы. </li>
          </ul>
          <p>
            Более подробную консультацию вы сможете получить у специалистов
            нашего Центра:
            <br /> по телефону{" "}
            <a href={`tel:${process.env.REACT_APP_PHONE_USER}`}>
              +7 (911) 740 - 02 - 90
            </a>
            <br /> или по адресу: г. Санкт-Петербург, улица Салова, 37А
          </p>
        </div>
        <Sidebar />
      </div>
    </div>
  );
}

export default About;
