import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";

function TargetComponent(props) {
  return (
    <div>
      <h1>Обучение по профессии частный охранник</h1>
      <div>
        <p>
          Учебным центром &laquo;Собрат&raquo; осуществляется подготовка
          охранников 4, 5 и 6 разряда. Каждый выпускник сдает квалификационный
          экзамен, по итогам которого аттестационной комиссией выдается
          свидетельство, необходимое для получения лицензии.
        </p>
        <p>
          В ходе обучения проводится правовая и медицинская подготовка, а также
          осваиваются навыки обращения со служебным оружием и применения
          специальных средств охраны.
        </p>
        <p>
          В распоряжении слушателей наших курсов - новейшие тренажеры и
          инструктора высочайшего профессионального уровня. Методика обучения
          разработана таким образом, чтобы обеспечить получение как
          теоретических, так и практических знаний. Во время практических
          занятий моделируются реальные ситуации, что позволяет нашим
          выпускникам в будущем чувствовать себя уверенно в любых
          обстоятельствах (даже форс-мажорных).
        </p>
        <p>
          Вам нужна более подробная информация? Звоните по телефону{" "}
          <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
            {process.env.REACT_APP_TELEPHONE_USER}
          </a>
          , и специалисты нашего Центра обязательно проконсультируют вас.
        </p>
      </div>
    </div>
  );
}

function GuardTraining(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default GuardTraining;
