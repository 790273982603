import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Order from "./Order";

function Orders(props) {
  const [orders, setOrders] = useState([
    {
      id: 1,
      title:
        "Приказ МВД России от 02.07.14 № 442 «Об утверждении типовых дополнительных профессиональных программ для руководителей частных охранных организаций».",
      src: "/uploads/docs/orders/102-49190.pdf",
    },
    {
      id: 2,
      title:
        'Приказ Министерства науки и образования РФ № 909 "К минимуму содержания дополнительной профессиональной программы повышения квалификации руководителей частных охранных организаций"',
      src: "/uploads/docs/orders/103-84356.pdf",
    },
    {
      id: 3,
      title:
        'Приказ Министерства науки и образования РФ № 430 "О требованиях к минимуму содержания программы профессиональной подготовки частных охранников" от 26.04.2010',
      src: "/uploads/docs/orders/104-60264.pdf",
    },
    {
      id: 4,
      title:
        'Приказ МВД РФ № 716 "О внесении изменений в нормативные правовые акты МВД России" от 21.09.2009',
      src: "/uploads/docs/orders/105-30045.pdf",
    },
    {
      id: 5,
      title:
        'Приказ МВД РФ № 288 "О мерах по реализации постановления правительства РФ" от 21.07.1998 № 814',
      src: "/uploads/docs/orders/106-44640.pdf",
    },
    {
      id: 6,
      title:
        'Приказ МВД РФ № 447 "О мерах по совершенствованию деятельнгости ОВД по лицензированию и осуществлению контроля за частной детективной и охранной деятельностью на территории РФ" от 19.06.2006',
      src: "/uploads/docs/orders/107-37397.pdf",
    },
    {
      id: 7,
      title:
        'Приказ МВД РФ № 568 "О порядке проведения ОВД РФ периодических проверок частных охранников, частных детективов (сыщиков) и работников юридических лиц с особыми уставными задачами на пригодность к действиям в условиях, связанных с применением огнестрельного оружия испециальных средств" от 15.07.2005',
      src: "/uploads/docs/orders/108-55795.pdf",
    },
    {
      id: 8,
      title:
        'Приказ МВД РФ № 650 "О мерах по реализации постановления Правительства РФ от 22 апреля 1997г. № 460" от 07.10.1997',
      src: "/uploads/docs/orders/279-58797.pdf",
    },
    {
      id: 9,
      title:
        'Приказ МВД РФ от 28 мая 2012 г. №543 "Об утверждении административного регламента Министерства Внутренних Дел Российской Федерации по предоставлению государственной услуги по приему квалификационного экзамена у граждан Российской Федерации, прошедших обучение по программе профессиональной подготовки частных охранников"',
      src: "/uploads/docs/orders/280-21974.pdf",
    },
    {
      id: 10,
      title:
        'Приказ МВД РФ от 29 июня 2012 г. №647 "Об утверждении положения о проведении органами внутренних дел Российской Федерации периодических проверок частных охранников и работников юридических лиц с особыми уставными задачами на пригодность к действиям в условиях, связанных с применением огнестрельного оружия и специальных средств"',
      src: "/uploads/docs/orders/281-7940.pdf",
    },
    {
      id: 11,
      title:
        'Приказ МВД РФ от 14 июля 2012 г. №696 "Об утверждении административного регламента Министерства Внутренних Дел Российской Федерации по предоставлению государственной услуги по проведению добровольной государственной дактилоскопической регистрации в Российской Федерации"',
      src: "/uploads/docs/orders/282-70460.pdf",
    },
    {
      id: 12,
      title:
        'Приказ ГУВД г. Санкт-Петербурга и Ленинградской области № 268 "О мерах по реализации отдельных положений закона РФ - О частной детективной и охранной деятельности в РФ - и приказа МВД РФ от 12 апреля 1999 года № 288 - О мерах по реализации постановления правительства РФ от 21 июля 1998 года № 814" от 21.07.1998 № 814 от 11 апреля 2000г.',
      src: "/uploads/docs/orders/372-15912.pdf",
    },
  ]);
  return (
    <div className="d-flex flex-row container my-4">
      <div className="row">
        <div className="col-lg-8 mb-5 px-5">
          <h3>ПРИКАЗЫ</h3>
          <Order orders={orders} />
        </div>
        <Sidebar />
      </div>
    </div>
  );
}

export default Orders;
