import React from "react";
import { Routes, Route } from "react-router-dom";
// Шапка и подвал
import Header from "../Navs/Header/Header";
import Footer from "../Navs/Footer/Footer";

// 404
import Page404 from "../404/Page404";

// Домашняя
import Home from "../Home/Home";

// Об организации
import About from "../Organisation/About/About";
import Trainers from "../Organisation/Trainers/Trainers";
import Media from "../Organisation/Media/Media";
import PriceList from "../Organisation/PriceList/PriceList";
import Organisation from "../Organisation/Organisation";

// Школа охранников
import GuardsSchool from "../GuardsSchool/GuardsSchool";
import CertRenewal from "../GuardsSchool/CertRenewal/CertRenewal";
import GuardTraining from "../GuardsSchool/GuardTraining/GuardTraining";
import GuardExam from "../GuardsSchool/GuardExam/GuardExam";
import PeriodicTest from "../GuardsSchool/PeriodicTest/PeriodicTest";
import RefreshTraining from "../GuardsSchool/RefreshTraining/RefreshTraining";
// import WorkAsGuard from "../GuardsSchool/WorkAsGuard/WorkAsGuard";
import GuardLicensing from "../GuardsSchool/GuardLicensing/GuardLicensing";
import GuardCert from "../GuardsSchool/GuardCert/GuardCert";

// Безопасное обращение с оружием
import SafeGunHandling from "../SafeGunHandling/SafeGunHandling";
import Study from "../SafeGunHandling/Study/Study";
import GunLicense from "../SafeGunHandling/GunLicense/GunLicense";
import GunPermit from "../SafeGunHandling/GunPermit/GunPermit";
import GunPermitRenewal from "../SafeGunHandling/GunPermitRenewal/GunPermitRenewal";
import ShootingRange from "../SafeGunHandling/ShootingRange/ShootingRange";
import MedicalBoard from "../SafeGunHandling/MedicalBoard/MedicalBoard";

// Документы
import Docs from "../Docs/Docs";
import Resolutions from "../Docs/Resolutions/Resolutions";
import Orders from "../Docs/Orders/Orders";
import Laws from "../Docs/Laws/Laws";

// Новости и контакты
// import News from "../News/News";
import Contacts from "../Contacts/Contacts";

export default function App() {
  return (
    <>
      <Header />
      <main className="container flex-shrink-0 mt-lg-4 mb-auto bg-white px-0">
        <Routes>
          {/* Домашняя */}
          <Route path="/" element={<Home />} />
          {/* Об организации */}
          <Route path="/organisation">
            <Route index element={<Organisation />} />
            <Route path="trainers" element={<Trainers />} />
            <Route path="price-list" element={<PriceList />} />
            <Route path="media" element={<Media />} />
            <Route path="about" element={<About />} />
          </Route>
          {/* Школа охранников */}
          <Route path="/guards-school">
            <Route index element={<GuardsSchool />} />
            <Route path="security-guard-certificate" element={<GuardCert />} />
            <Route
              path="renewal-of-security-guard-certificate"
              element={<CertRenewal />}
            />
            <Route
              path="training-for-security-guard"
              element={<GuardTraining />}
            />
            <Route path="security-guard-exam" element={<GuardExam />} />
            <Route path="periodic-test" element={<PeriodicTest />} />
            <Route path="refresher-training" element={<RefreshTraining />} />
            <Route
              path="licensing-of-security-guard"
              element={<GuardLicensing />}
            />
          </Route>
          {/* Безопасное обращение с оружием */}
          <Route path="/safe-gun-handling">
            <Route index element={<SafeGunHandling />} />
            <Route path="safe-gun-handling-study" element={<Study />} />
            <Route path="license-for-civil-gun" element={<GunLicense />} />
            <Route path="gun-permit" element={<GunPermit />} />
            <Route path="renewal-gun-permit" element={<GunPermitRenewal />} />;
            <Route path="shooting-range" element={<ShootingRange />} />
            <Route path="medical-board" element={<MedicalBoard />} />
          </Route>
          {/* Новости
          <Route path="news" element={<News />} /> */}
          {/* Документы */}
          <Route path="/docs">
            <Route index element={<Docs />} />
            <Route
              path="resolution-of-russian-federation"
              element={<Resolutions />}
            />
            <Route path="orders" element={<Orders />} />
            <Route path="laws-of-russia" element={<Laws />} />
          </Route>
          {/* Контакты */}
          <Route path="contacts" element={<Contacts />} />
          {/* 404 */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}
