import React, { useState } from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";
import Carousel from "react-bootstrap/Carousel";
import { v4 as uuidv4 } from "uuid";

function TargetComponent(props) {
  const [photos, setPhotos] = useState([
    {
      src: "/uploads/shooting-range/1.jpg",
      title: "",
      description: "",
    },
    {
      src: "/uploads/shooting-range/2.jpg",
      title: "",
      description: "",
    },
    {
      src: "/uploads/shooting-range/tir.jpg",
      title: "",
      description: "",
    },
  ]);
  return (
    <div>
      <h1>Стрелковый тир</h1>
      <div>
        <p>
          Учебный центр &laquo;Собрат&raquo; - многофункциональная организация,
          которая оказывает широчайший спектр услуг как для частных охранников,
          так и для владельцев гражданского оружия. На территории нашего Центра
          есть прекрасно оборудованный боевой тир.
        </p>
        <h2> Стрелковый тир прeдлагает</h2>
        <ul>
          <li>25-метровая галерея с тремя направлениями стрельбы;</li>
          <li>инструкторы&nbsp;высочайшего профессионального уровня;</li>
          <li>организация тренировочных стрельб для охранных предприятий.</li>
        </ul>
        <p>
          Учебный центр &laquo;Собрат&raquo;, кроме стрелкового тира, также
          предоставляет следующие услуги:
        </p>
        <ul>
          <li>
            обучение в школе охранников: получение и продление удостоверения
            частного охранника, обучение профессии (4,5,6 разряд), сдача
            квалификационного экзамена, прохождение периодической проверки,
            повышение квалификации, подготовка сотрудников юридических лиц с
            особыми уставными задачами;
          </li>
          <li>обучение безопасному обращению с оружием;</li>
        </ul>
        Наш огнестрельный тир соответствует всем требованиям безопасности. Если
        вы хотите узнать более подробную информацию о наших услугах, звоните по
        телефону:{" "}
        <a href={`tel:${process.env.REACT_APP_TELEPHONE_HREF}`}>
          {process.env.REACT_APP_TELEPHONE_USER}
        </a>
      </div>
      <Carousel fade className="w-100 mt-4">
        {photos.map((photo) => (
          <Carousel.Item
            key={uuidv4()}
            className="d-flex"
            style={{ height: "50vh" }}
          >
            <img
              className="img-fluid rounded-3"
              src={photo.src}
              alt={photo.title}
              style={{
                width: "100%",
                heigth: "100%",
                objectFit: "cover",
              }}
            />
            <Carousel.Caption>
              <h3 className="fs-3 fw-bold">{photo.title}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

function ShootingRange(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default ShootingRange;
