import React from "react";
import Sidebar from "./Sidebar";

function Docs(props) {
  return (
    <div className="d-flex flex-row container my-4">
      <div className="row">
        <div className="col-lg-8 mb-5 px-5">
          <h3>Нормативная документация</h3>
          <div className="my-2">
            <p>
              Нормативная база нашего портала содержит всю необходимую
              информацию по частной охранной и детективной деятельности, а также
              по обороту гражданского оружия.
            </p>
            <p>Выберете необходимый раздел и ознакомтесь с документами.</p>
          </div>
        </div>
        <Sidebar />
      </div>
    </div>
  );
}

export default Docs;
