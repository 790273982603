import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Resolution from "./Resolution";

function Resolutions(props) {
  const [resolutions, setResolution] = useState([
    {
      id: 1,
      title:
        'Постановление Правительства РФ от 31 марта 2009 г. № 277 "Об утверждении положения о лицензировании образовательной деятельности" ( в ред. Постановления Правительства РФ 20.06.2020 № 897)',
      src: "/uploads/docs/resolutions/1 Постановление Правительства РФ от 31.03.2009 N 277.pdf",
    },
    {
      id: 2,
      title:
        'Постановление Правительства РФ от 22 апреля 1997г. № 460 "О мерах по обеспечению юридических лиц с особыми уставными задачами боевым ручным стрелковым оружием"((в ред. Постановлений Правительства РФ от 29.02.2000 N 173, от 29.05.2006 N 334, от 06.10.2011 N 824, от 23.11.2012 N 1214, от 19.04.2017 N 467, от 26.06.2018 N 730, от 17.04.2021 N 613)',
      src: "/uploads/docs/resolutions/2 Постановление Правительства РФ от 22.04.97 N 460 О мерах по обеспечению юридических лиц с особыми.pdf",
    },
    {
      id: 3,
      title:
        'Постановление Правительства РФ от 21 июня 1998 г. № 814 "О мерах по регулированию оборота гражданского и служебного оружия и патронов к нему на территории РФ"',
      src: "/uploads/docs/resolutions/3 Постановление Правительства РФ от 21.07.98 N 814 О мерах по регулированию оборота гражданского и.pdf",
    },
    {
      id: 4,
      title:
        'Постановление Правительства РФ от 14 августа 1992 г. № 587 "Вопросы негосударственной (частной) и негосударственной (частной) сыскной деятельности"',
      src: "/uploads/docs/resolutions/4 Постановление Правительства РФ от 14.08.92 N 587 Вопросы частной детективной (сыскной) и частной.pdf",
    },
    {
      id: 5,
      title:
        'Постановление правительства РФ от 11 марта 2011 г. №164 "Об осуществлении государственного контроля (надзора) в сфере образования"( в ред. Постановление Правительства РФ от 29.03.2014 N 245)',
      src: "/uploads/docs/resolutions/5 Постановление Правительства РФ от 11.03.2011 N 164 Об осуществлении государственного контроля.pdf",
    },
    {
      id: 6,
      title:
        'Постановление правительства РФ от 16 апреля 2011 г. №274 "Правила инспектирования полицией подразделений охраны юридических лиц с особыми уставными задачами и подразделений ведомственной охраны"',
      src: "/uploads/docs/resolutions/6 Постановление Правительства РФ от 16.04.2011 N 274 Об утверждении Правил инспектирования полицией.pdf",
    },
    {
      id: 7,
      title:
        'Постановление правительства РФ от 23 июня 2011 г. №498 "О некоторых вопросах осуществления частной детективной (сыскной) и частной охранной деятельности"(в ред. Постановлений Правительства РФ от 21.11.2011 N 957, от 09.09.2015 N 948, от 26.06.2018 N 729, от 02.09.2019 N 1142, от 23.12.2020 N 2226)',
      src: "/uploads/docs/resolutions/7 Постановление Правительства РФ от 23.06.2011 N 498 О некоторых вопросах осуществления частной.pdf",
    },
  ]);
  return (
    <div className="d-flex flex-row container my-4">
      <div className="row">
        <div className="col-lg-8 mb-5 px-5">
          <h3>ПОСТАНОВЛЕНИЯ ПРАВИТЕЛЬСТВА РФ</h3>
          <Resolution resolutions={resolutions} />
        </div>
        <Sidebar />
      </div>
    </div>
  );
}

export default Resolutions;
