import React from "react";
import SamplePage from "../../SamplePage/SamplePage";
import { sidebarLinks } from "../sidebarLinks";
import Card from "react-bootstrap/Card";

function TargetComponent(props) {
  return (
    <div>
      <h1>Удостоверение частного охранника</h1>
      <div>
        <p>
          Для того, чтобы получить удостоверение охранника, гражданину
          необходимо пройти профессиональную подготовку, сдать квалификационный
          экзамен, осуществить обязательную дактилоскопическую регистрацию,
          получить медицинское заключение формы 002, оплатить государственную
          пошлину и подать личное дело в Центр лицензионно-разрешительных работ.
        </p>
        <p>
          <b>
            Для формирования личного дела гражданам необходимо предоставить
            следующий пакет документов:
          </b>
        </p>
        <ul>
          <li>паспорт гражданина РФ; </li>
          <li>
            свидетельство об окончании курса обучения по программе частного
            охранника;
          </li>
          <li>
            медицинская форма 002 с заключением об отсутствии заболеваний,
            препятствующих исполнению обязанностей частного охранника;
          </li>
          <li>
            заключение по форме 454/у-06 (химико-токсикологическое
            исследование);
          </li>
          <li>
            справка о прохождении обязательной дактилоскопической регистрации;
          </li>
          <li>
            копия трудовой книжки (для лиц, проходивших государственную службу -
            выписка из приказа об увольнении);
          </li>
          <li>
            квитанция об оплате государственной пошлины на сумму - 2000 рублей;
          </li>
          <li>фотографии 4см x 6см, матовые, цветные - 4 штуки;</li>
          <li>
            справка о прохождении дактилоскопии - получается в ЛРР по месту
            прописки (с собой паспорт), бесплатно
          </li>
          <li>
            логин и пароль от портала государственных услуг - получаете в МФЦ (с
            собой паспорт и снилс), бесплатно
          </li>
        </ul>
        <p>
          Отметим, что лицензия охранника и удостоверение охранника - различные
          названия одного и того же документа, который понадобится вам при
          осуществлении трудоустройства в большинство организаций, которые
          оказывают охранные услуги.
        </p>
        <Card>
          <Card.Img
            variant="top"
            alt="получение удостоверения частного охранника"
            src="/uploads/udostoverinie.jpg"
            title="Удостоверение частного охранника"
            className="img-thumbnail img-fluid"
          />
          <Card.Body>
            <Card.Text className="text-center">
              Форма удостоверения частного охранника
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

function GuardCert(props) {
  return (
    <SamplePage sidebarLinks={sidebarLinks} TargetComponent={TargetComponent} />
  );
}

export default GuardCert;
