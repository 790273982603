import React, { useState } from "react";
import Sidebar from "../Sidebar";
import SingleTrainer from "./SingleTrainer";

function Trainers(props) {
  const [trainers, setTrainers] = useState([
    {
      name: "Митрофанов Дмитрий Михайлович",
      bio: "Инструктор по огневой и тактической подготовке Учебного центра \u00ABСобрат\u00BB. Имеет огромный опыт проведения учебных стрельб со всех видов штатного стрелкового оружия, в галереях тиров и полигонов, в различных погодных условиях и времени суток, а также в условиях приближённым к боевым.",
      src: "/uploads/trainers/1.jpg",
    },
  ]);
  return (
    <div className="d-flex flex-row container my-4">
      <div className="row">
        <div className="col-lg-8 mb-5 px-5">
          <h3>Инструкторы нашего центра</h3>
          <SingleTrainer trainers={trainers} />
        </div>
        <Sidebar />
      </div>
    </div>
  );
}

export default Trainers;
