import React from "react";
import Card from "react-bootstrap/Card";
import "./singleTrainer.css";

function SingleTrainer({ trainers }) {
  return trainers.map((trainer, index) => (
    <Card key={index} className="my-4">
      <Card.Img
        variant="top"
        src={trainer.src}
        className="d-block img-fluid rounded-3 trainer-img"
      />
      <Card.Body>
        <Card.Header className="bg-white fs-5">{trainer.name}</Card.Header>
        <Card.Text>{trainer.bio}</Card.Text>
      </Card.Body>
    </Card>
  ));
}

export default SingleTrainer;
